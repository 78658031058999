export default {
  title: "ダッシュボード",
  iconCards: {
    1: {
      header: {
        content: "１運行の平均運転時間"
      },
      footer: {
        content: "目標運転時間"
      }
    },
    2: {
      header: {
        content: "平均実車率"
      },
      footer: {
        content: "目標実車率"
      }
    },
    3: {
      header: {
        content: "平均安全運転スコア"
      },
      footer: {
        content: "目標運転スコア"
      }
    },
    4: {
      header: {
        content: "平均燃費"
      },
      footer: {
        content: "目標燃費"
      }
    }
  },
  reading: "読み込み中...",
  year: "年",
  month: "月",
  people: "名",
  monthly: "月度",
  displayData: "のデータを表示",
  driverName: "ドライバー",
  driverId: "ドライバーID",
  driversWithPotentiallyExcessiveHours: {
    title: "拘束時間超過事前予測",
    predictionRestraintTime: "予測拘束時間",
    targetPeople: "対象人数",
    time: "時間",
    overTime: "時間超",
    restraint: "現在の拘束時間",
  },
  overTime: {
    title: "時間外労働超過予測",
    settings: "時間外労働設定",
    monthlySummary: "現在の時間外労働時間",
    remainingTime: "残り取得可能時間",
    predictionMonthlySummary: "予測時間外労働時間",
  },
  actualCar: {
    title: "実車率推移",
    ActualCarRate: "実車率",
    carNumber: "車番",
  },
  safeDriving: {
    title: "安全運転スコア",
    point: "点"
  },
  drivingDistance: {
    title: "走行距離推移",
  },
  fuel: {
    title: "燃費推移"
  },
  predictedOvertime: {
    title: "時間外労働超過予測",
    settings: "時間外労働設定",
    workTime: "現在の労働時間",
    predictedWorkTime: "予測労働時間"

  },
  restraintAndDrivingTime: {
    title: "拘束時間超過事前予測",
    alertSettings: "拘束時間アラート設定"
  }
}
