<template>
  <template v-if="!!formInputs?.form?.daily_early">
    <v-card class="my-4 pa-4">
      <v-card-title class="headline">DAILY YEARLY</v-card-title>
      <v-card-text>
        <div class="row mr-3 v-theme--light">
          <Input
              label="STARTUP"
              v-model="form.startup"
              :isShow="formFieldOptions.startup.isShow"
              :isRequired="formFieldOptions.startup.isRequired"
              :formInputs="formInputs"
              :readonly="selectDefault && readonlyInputs.includes('startup')"
              :errors="formFieldOptions.startup.errors"
          />
        </div>
      </v-card-text>
    </v-card>
  </template>
</template>

<script>
import Input from "@/components/VehicleFormParts/SettingFormInput.vue";

export default {
  props: {
    modelValue: {},
    formInputs: {},
    selectDefault: {
      type: Boolean,
      default: false
    },
    errors: {},
  },
  data() {
    return {
      form: {
        startup: '',
      },
      readonlyInputs: [],
      errorKeyPrefix: 'initial_params.daily_early',
    }
  },
  methods: {
    getShowAndRequiredAndErrors(parentKey, formKeys) {
      if (!this.formInputs?.form[parentKey]) {
        return {
          'isShow': false,
          'isRequired': false,
          'errors': null
        };
      }

      const keys = Object.keys(this.formInputs.form[parentKey]);

      const result = formKeys.map(formKey => {
        return {
          'isShow': keys.includes(formKey),
          'isRequired': !!this.formInputs.form[parentKey][formKey]?.required,
          'errors': this.errors[this.errorKeyPrefix + '.' + formKey]
        };
      });

      return {
        'isShow': result.every(item => item.isShow),
        'isRequired': result.every(item => item.isRequired),
        'errors': result.map(item => item.errors).filter(error => error)
      };
    }
  },
  computed: {
    formFieldOptions() {
      const parentKey = 'daily_early'

      return {
        startup: this.getShowAndRequiredAndErrors(parentKey, ['startup']),
      };
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.form = Object.assign(this.form, this.modelValue)
      }
    },
    formInputs: {
      handler() {
        if (!this.formInputs?.form?.daily_early) return
        const key = Object.keys(this.formInputs.default_params)[0]
        this.readonlyInputs = Object.keys(this.formInputs.default_params[key]?.parameters?.daily_early ?? [])
      }
    },
    form: {
      handler() {
        this.$emit('update:modelValue', this.form)
      },
      deep: true
    },
  },
  components: {
    Input,
  },
}
</script>

<style scoped>
.headline {
  font-size: 24px;
  font-weight: bold;
  color: #0088a6;
}
</style>
