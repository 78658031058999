<template>
  <DetailModal
    v-model='modalState'
    :details="details"
    :threshold_secconds="info.threshold_secconds"
    :start_date="start_date"
    :end_date="end_date"
  />
  <div class="col-xl-6 col-md-12" @click="openDetailModal">
    <div class="dashboard-panel predicted-overtime-panel" >
      <template v-if="loading">
        <div class="panel-loading">
            <label >
              {{ i18n.global.t('pages.dashboard.reading') }}
            </label>
          </div>
      </template>
      <stats-card>
        <template v-slot:header>
          <label class="chart-card-title">
            {{ i18n.global.t('pages.dashboard.overTime.title') }}
          </label>
        </template>
        <template v-slot:icon>
          <span class="material-symbols-outlined big-toys card-icon">
            more_time
          </span>
        </template>
        <template v-slot:content>
          <p class="card-content fw-bold text-left">
            {{ `${info.number_of_drivers} ${i18n.global.t('pages.dashboard.people')}` }}
          </p>
        </template>
        <template v-slot:footer>
          <label class="mr-1" >
            {{ i18n.global.t('pages.dashboard.overTime.settings') }}
          </label>
          <label class="fs-6 pl-5">{{ info.threshold }} </label>
        </template>
      </stats-card>
    </div>
  </div>
</template>

<style>
.predicted-overtime-panel .status-card-body {
  width: 100%;
  position: relative;
  justify-content: center;
}
</style>
<style scoped>
.dashboard-panel {
  position: relative;
  height: 100%;
}
.panel-loading {
  height: 100%;
  position: absolute;
  z-index: 1000;
  background-color: rgba(80,80,80,0.5);
  right: 0;
  left: 0;
}
.panel-loading>label {
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
</style>

<script>
  import StatsCard from '@/components/Cards/StatsCard'
  import DetailModal from '@/components/DashboardCards/OverTimeDetailModal'
  import Api from "@/services/api/ApiServiceFabrick";
  import i18n from '@/lang/i18n';

  export default {
    setup() {
      return {
        i18n
      }
    },
    props: {
      operationOfficeId: null,
    },
    data () {
      return {
        info: {
          threshold: '-',
          number_of_drivers: '-',
        },
        modalState: false,
        loading: true,
        details: [],
        start_date: '',
        end_date: ''
      }
    },
    mounted() {
      this.getData();
    },
    components: {
      StatsCard,
      DetailModal,
    },
    methods: {
      openDetailModal() {
        this.modalState = true
      },
      getData() {
        Api().getOverTimes(this.operationOfficeId).then((res) => {
          this.details = res.details
          this.start_date = res.start_date
          this.end_date = res.end_date
          if (res.threshold != undefined) {
            this.info.threshold = res.threshold
            this.info.threshold_secconds = res.threshold_secconds
            this.info.number_of_drivers = res.alert_count
          }
          this.loading = false
          this.details = res.over_time_data.sort((a, b) => {
            return (b.restraint) - (a.restraint)
          });
        })
      }
    },
    watch: {
      operationOfficeId: {
        handler() {
          this.getData()
        }
      }
    }
  }
</script>

<style scoped>
.card-icon {
  color: orange;
}
</style>

