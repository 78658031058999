

import {defineComponent, PropType} from "vue";
import {formatDate} from "@/services/functions";
import {sortType} from "@/services/consts";
import DeviceFirmware from "@/types/DeviceFirmware";
import {sortField} from "@/types/requests/getDeviceFirmwareListRequest";
import TableSort from "@/components/template/TableSort.vue";
import i18n from '@/lang/i18n';

export default defineComponent({
  setup() {
    return {
      formatDate,
      sortField,
      sortType,
      i18n
    }
  },
  data() {
    return {
      deviceListSort: {
        sort_type: undefined as string | undefined,
        sort: undefined as string | undefined,
      },
      selectedDevices: {} as { [key: string]: boolean },
      selectAll: false as boolean,
    }
  },
  props: {
    isDeviceListLoading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    devices: {
      type: Array as PropType<DeviceFirmware[]>,
      required: true,
    },
  },
  emits: ['sort-change', 'update-device-click', 'update-selected-items'],
  name: "DeviceList",
  computed: {},
  watch: {
    deviceListSort: {
      handler() {
        this.$emit('sort-change', this.deviceListSort)
      },
      deep: true
    },
    devices: {
      handler() {
        this.initSelectedDevices()
      },
    },
    selectedDevices: {
      handler() {
        const selectedDevices: Array<string> = []
        Object.keys(this.selectedDevices).forEach((key) => {
          if (this.selectedDevices[key]) {
            selectedDevices.push(key)
          }
        })
        this.$emit('update-selected-items', selectedDevices)
      },
      deep: true
    },
  },
  methods: {
    toggleSort(sortFiledValue: string) {
      
      let updatedSortField = false;
      if (sortFiledValue !== this.deviceListSort.sort) {
        updatedSortField = true
      }
      
      this.deviceListSort.sort = sortFiledValue;
      if (this.deviceListSort.sort_type === undefined || updatedSortField) {
        this.deviceListSort.sort_type = sortType.ASK
      } else {
        this.deviceListSort.sort_type = this.deviceListSort.sort_type === sortType.DESC ? sortType.ASK : sortType.DESC
      }
    },
    changeSort(sortFiledValue: string, needSortType: sortType): void {
      if ( this.deviceListSort.sort == sortFiledValue && this.deviceListSort.sort_type == needSortType ){
        return
      }
      this.deviceListSort.sort = sortFiledValue;
      this.deviceListSort.sort_type = needSortType
    },
    initSelectedDevices(): void {
      this.selectedDevices = {}
      this.selectAll = false
      this.devices.map(device => {
        this.selectedDevices[device.id] = false
      })
    },
    toggleSelectAll(): void {
      Object.keys(this.selectedDevices).forEach((key) => {
        this.selectedDevices[key] = this.selectAll
      })
    }
  },
  components: {
    TableSort,
  }
})
