<template>
  <DetailModal v-model='modalState' :details="details" :start_date="start_date" :end_date="end_date"/>
  <div class="col-xl-6 col-md-12" @click="openDetailModal">
    <div class="dashboard-panel" >
      <template v-if="loading">
        <div class="panel-loading">
            <label >
              {{ i18n.global.t('pages.dashboard.reading') }}
            </label>
          </div>
      </template>
      <doughnut-chart-card
        :chartData="data"
        :options="options"
      >
        <template v-slot:header>
          <label class="chart-card-title">
            {{ i18n.global.t('pages.dashboard.driversWithPotentiallyExcessiveHours.title') }}
          </label>
        </template>
        <template v-slot:left-panel>
          <div class="table-list ml-3">
            <table border="2" class="table table-bordered table-striped text-center">
              <tbody>
                <tr>
                  <td>
                    {{ i18n.global.t('pages.dashboard.driversWithPotentiallyExcessiveHours.predictionRestraintTime') }}
                  </td>
                  <td>
                    {{ i18n.global.t('pages.dashboard.driversWithPotentiallyExcessiveHours.targetPeople') }}
                  </td>
                </tr>
                <tr v-for="(row, i) in tableData" :key="'restraint-driver-'+i">
                  <td>{{ row.time }}</td>
                  <td>{{ row.count }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </doughnut-chart-card>
    </div>
  </div>
</template>

<style scoped>
.dashboard-panel {
  position: relative;
  height: 100%;
}
.panel-loading {
  height: 100%;
  position: absolute;
  z-index: 1000;
  background-color: rgba(80,80,80,0.5);
  right: 0;
  left: 0;
}
.panel-loading>label {
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.table td {
  min-height: 49px;
}
</style>

<script>
  import DoughnutChartCard from '@/components/Cards/DoughnutChartCard.vue'
  import DetailModal from '@/components/DashboardCards/DriversWithPotentiallyExcessiveHoursDetailModal'
  import Api from "@/services/api/ApiServiceFabrick";
  import i18n from '@/lang/i18n';

  export default {
    setup() {
      return {
        i18n
      }
    },
    components: {
      DetailModal,
      DoughnutChartCard,
    },
    props: {
      operationOfficeId: null,
    },
    data () {
      return {
        data: {
          labels: [],
          datasets: [],
        },
        options: {},
        modalState: false,
        tableData: ['', '', ''],
        loading: true,
        details: [],
        start_date: '',
        end_date: ''
      }
    },
    mounted() {
      this.getData();
    },
    methods: {
      openDetailModal() {
        this.modalState = true
      },
      getData() {
        Api().getRestraintAndWorkingTimes(this.operationOfficeId).then((res) => {
          const data = res.restraint_data
          this.start_date = res.start_date
          this.end_date = res.end_date
          const labels = []
          const colors = []
          const tableData = []
          const values = []
          this.details = res.details
          data.forEach((v) => {
            if (v.threshold == null) {
              labels.push('-')
              tableData.push({ time: '-', count: v.count})
              values.push(0)
            } else {
              labels.push(v.threshold + i18n.global.t('pages.dashboard.driversWithPotentiallyExcessiveHours.time'))
              tableData.push({
                time: v.threshold + i18n.global.t('pages.dashboard.driversWithPotentiallyExcessiveHours.overTime'),
                count: v.count
              })
              values.push(v.count)
            }
            colors.push(v.color)
          })

          res.details.sort((a, b) => {
            return (b.restraint) - (a.restraint)
          });

          this.data = {
            labels: labels,
            datasets: [
              {
                data: values,
                backgroundColor: colors,
              },
            ]
          }
          this.options = {
            plugins: {
              legend: {
                position: 'bottom',
                labels: {
                  boxWidth: 10,
                }
              },
            }
          }
          this.tableData = tableData,
          this.loading = false
        })
      }
    },
    watch: {
      operationOfficeId: {
        handler() {
          this.getData()
        }
      }
    }
  }
</script>
