export default {
  iconToolTips: {
    upload: "「ファイルアップロード」で生成した動画です。",
    request: "「リクエスト作成」で取得した動画です。"
  },
  list: {
    cameraType: "カメラ種類",
    sort: {
      asc: "昇順で並び替え",
      desc: "降順で並び替え",
    },
    id: "ID",
    numberPlate: "車番",
    driverName: "ドライバー名",
    department: "組織",
    group: "グループ",
    svrCount: "動画数",
    uploadStatus: "アップロード状況",
    startTime: "開始日時",
    endTime: "終了日時",
    createdBy: "設定者",
    createdAt: "設定日",
    exclusion: "削除除外",
    noVehicle: "車両がありません。",
    notExistsDevice: "デバイス無し",
    notAffiliated: "所属なし",
    getVideoList: "動画一覧",
    deviceModelType: "型番",
    cancelBtn: "中断する",
    pleaseSelectOperationOffice: "運営会社を選択してください。",
  },
  statuses: {
    ready: '準備中',
    uploading: 'アップロード中',
    submitted: 'アップロード完了',
    progressing: '動画変換中',
    unready: '確認中',
    accepted: 'アップロード中',
    processing: '処理中',
    error: 'エラー',
    rejected: '',
    complete: '完了',
    no_input_skipped: '動画はありません',
    removed: '削除済み',
    canceled: '変換できませんでした',
    aborted: '中止',
    failed: '失敗'
  },
  startDateTime: "開始日時",
  selectedPeriod: "選択期間",
  period: "取得期間",
  requestCameraType: "カメラを選択してください。",
  endDateTime: "終了日時",
  uploadBtn: "ファイルアップロード",
  requestBtn: "リクエスト作成",
  uploading: "アップロード中",
  hour: "時",
  minute: "分",
  removeBtn: '削除',
  waitting: "リクエスト処理中",
  requestAvailable: "リクエスト可能",
  downloadBtn: 'ダウンロード',
  notice: "リクエストは車両がエンジンONの時におこなってください。\nエンジンOFFの時にリクエストをしたものは中止となります。",
  uploadPanel: {
    uploadBtn: "アップロード",
    uploading: "アップロード中",
    success: "アップロードに成功しました。"
  },
  requestPanel: {
    requestBtn: "リクエスト作成",
    requesting: "リクエスト中",
    success: "リクエスト作成に成功しました。",
    failed: "リクエスト作成に失敗しました。",
    notSelectVehicle: "車両を選択してください",
    loading: "読み込み中",
    deliveryDoesNotExists: "メディアが見つかりません",
    emptyCameraSettings: "カメラが設定されていません",
  },
  cameraName: {
    front: "カメラ1",
    rear: "カメラ2",
    in: "カメラ3",
  },
  removeMessages: {
    success: '削除に成功しました。',
    confirmationModalTitle: 'この動画を削除します。よろしいですか？',
    confirmationModalBtnLabel: '削除',
    notificationModalTitle: '削除除外が設定されています。削除除外を解除してください。',
  },
  cancelMessages: {
    success: '中断に成功しました。',
    confirmationModalTitle: 'リクエストを中断します。よろしいですか？',
    confirmationModalContent: 'リクエストを一度中断すると再開することはできません。新しくリクエストを作成してください。',
    confirmationModalBtnLabel: '実行',
  },
  holdMessages: {
    noticeMessage: '１車両につき動画が５０件を超えると、古いものから自動的に削除されます。※ただし「ファイルアップロード」からアップロードした動画は削除されません。',
    numberOfHoldingIsOverThreshold : '削除対象から除外できる件数の上限を超えました。最大10件まで削除対象から除外できます。',
    numberOfHoldingIsOverThresholdDetail : '最大10件まで削除対象から除外できます。',
    successHold: '削除対象から除外しました。',
    successIgnoreHold: '動画を削除対象にしました。',
    confirmationModalTitle: 'この動画は自動的に削除されます。よろしいですか？',
    confirmationModalBtnLabel: '変更',
  },
  previewModal: {
    speed: '速度',
  },
  errorMessages: {
    notSelect: "選択してください",
    hasNoDevice: "選択した車両はデバイスが紐づいていません",
    notSelectStartDateTime: "開始日時を指定してください。",
    notSelectEndDateTime: "終了日時を指定してください。",
    invalidRange: "開始日時より後の日時を指定してください。",
    invalidFileType: "拡張子.mpg .acc .wavのファイルのみアップロード可能です。",
    failedLoadMovies: "動画の読み込みに失敗しました",
    failedDownloadMovies: "ダウンロードに失敗しました",
  },
  uploadStatusExplanations: {
    ready: "リクエストを受付中です。",
    uploading: "サーバーへデータをアップロード中です。",
    submitted: "サーバーへのデータのアップロードが完了しました。",
    progressing: "アップロードされたデータを変換中です。",
    accepted: "サーバーへデータをアップロード中です。",
    processing: "サーバーにアップロードされたデータを変換中です。",
    error: "エラーが発生しました。",
    complete: "リクエストが正常に完了しました。",
    no_input_skipped: "動画がありません。",
    canceled: "動画の変換に失敗しました。",
    aborted: "デジタコはリクエストを受け付けられる状態ではありませんでした。",
    failed: "サーバーにアップロードされたデータの変換に失敗しました。",
  },
}
