import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-515dbe8f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-container v-theme--light" }
const _hoisted_2 = { class: "form-width" }
const _hoisted_3 = { class: "float-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FirmwareVersionAutoComplete = _resolveComponent("FirmwareVersionAutoComplete")!
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_validation_errors = _resolveComponent("validation-errors")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, { class: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          md: "4",
          sm: "12"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_FirmwareVersionAutoComplete, {
                modelValue: _ctx.selectedFirmware,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFirmware) = $event)),
                errorMessages: _ctx.errors?.firmware
              }, null, 8, ["modelValue", "errorMessages"]),
              _createVNode(_component_validation_errors, {
                class: "m-2",
                messages: _ctx.errors.activate_date
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Datepicker, {
                    class: "mb-0",
                    format: "yyyy/MM/dd",
                    locale: "ja",
                    placeholder: _ctx.i18n.global.t('pages.deviceFirmware.applyDate'),
                    modelValue: _ctx.form.activate_date,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.activate_date) = $event)),
                    errorMessage: _ctx.errors?.activate_date?.length ?? 0 > 0
                  }, null, 8, ["placeholder", "modelValue", "errorMessage"])
                ]),
                _: 1
              }, 8, ["messages"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "4",
          sm: "12",
          class: "v-theme--light"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_textarea, {
              label: "メモ",
              readonly: "",
              value: _ctx.selectedFirmware?.memo,
              "persistent-placeholder": "true",
              rows: "3"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "4",
          cols: "12"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_v_btn, {
                class: "ma-2 reset-btn",
                onClick: _ctx.clearForm
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.i18n.global.t('common.cancelBtn')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              (_ctx.updateAuthority)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    class: "ma-2 exec-btn",
                    onClick: _ctx.validateAndSendForm,
                    disabled: _ctx.isAttachButtonDisabled,
                    style: _normalizeStyle({
              backgroundColor: _ctx.isFirmwareDeviceAttaching ? '#B3B8BB' : '',
            })
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.attachBtnText), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled", "style"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}