<template>
  <div class="spot-list">
    <template v-if="listLoading">
      <div class="not-searched">
        <label>読み込み中</label>
      </div>
    </template>
    <v-table>
      <thead>
        <tr class="table-header-row">
          <th class="col-md-1" >ID</th>
          <th class="col-md-3" >名称</th>
          <th class="col-md-1" >{{ i18n.global.t('pages.spots.form.telephoneNumber') }}</th>
          <th class="col-md-4" >住所</th>
          <th class="col-md-1" >範囲</th>
          <th class="col-md-1" >最終更新日</th>
          <th class="col-md-1" >編集</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="items?.length != 0">
        <tr
          v-for="item in items"
          :key="'spot_id_' + item?.id"
        >
          <td> {{item.id}}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.telephone_number }}</td>
          <td>{{ item.address }}</td>
          <td>{{ item.radius }}</td>
          <td>{{ item.updated_at?.split(" ")[0] }}</td>
          <td class="d-flex">
            <update_modal
              :item="item"
              @updateItem="updateItem"
              :showFlash="showFlash"
              label="更新"
              :isUpdateAuthority="isUpdateAuthority"
              :isOperationOffice="isOperationOffice"
              :isSubManagementOffice="isSubManagementOffice"
            />
            <delete_modal
              v-if="isdeleteAuthority"
              :item="item"
              isDeleteAuthority="isdeleteAuthority"
              @change="updateItem"
              :showFlash="showFlash"
            />
          </td>
        </tr>
        </template>
        <template v-else-if="!listLoading">
          <tr>
            <td colspan="9">
              表にデータがありません。
            </td>
          </tr>
        </template>
      </tbody>
    </v-table>
  </div>
</template>

<script>
import UpdateModal from "@/components/SpotParts/SpotUpdateModal"
import DeleteModal from "@/components/SpotParts/SpotDeleteModal"
import i18n from '@/lang/i18n';
  export default {
    props: {
      items: [],
      listLoading: {
        type: Boolean,
        default: false
      },
      showFlash: {
        type: Function
      },
      isdeleteAuthority: {
        type: Boolean
      },
      isUpdateAuthority: {
        type: Boolean
      },
      isOperationOffice: {
        type: Boolean,
        default: false,
      },
      isSubManagementOffice: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      return {
        i18n,
      }
    },
    data() {
      return {
        form: {},
      }
    },
    computed: {
    },
    methods: {
      updateItem(item) {
        this.$emit('changeItem')
      },
    },
    watch: {
    },
    components: {
      update_modal: UpdateModal,
      delete_modal: DeleteModal,
    }
  }

</script>

<style>
.spot-list {
  position: relative;
}
.spot-list .v-table__wrapper{
  max-height: 34em;
  min-height: 20vw;
  overflow: auto;
}
.spot-list .v-table__wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.spot-list .v-table__wrapper::-webkit-scrollbar-thumb {
  background-color: rgb(150,150,150);
  border-radius: 5px;
  box-shadow: inset 3px 3px 3px rgba(255,255,255,0.2);
}
.spot-list .v-table__wrapper::-webkit-scrollbar-track {
  background-color: rgb(220,220,220);
  border-radius: 5px;
  box-shadow: inset 3px -3px 5px rgba(0,0,0,0.2);
}
.spot-list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
}
.spot-list table .fix-td {
  background-color: white;
  position: sticky;
  left: 0;
}
.spot-list table .table-header-row th {
  z-index: 1;
}
.spot-list table .table-header-row th:first-child {
  z-index: 2;
}
.checkbox-in-grid {
  padding: 1vm;
  height: inherit !important;
  display: inline-grid !important;
}
.not-searched {
  height: 100%;
  position: absolute;
  z-index: 1000;
  background-color: rgba(80,80,80,0.5);
  right: 0;
  left: 0;
}
.not-searched>label {
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
</style>
