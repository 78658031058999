
import { defineComponent, PropType } from 'vue';
import FirmwareVersionAutoComplete from '@/components/AutoCompletes/FirmwareVersionAutoComplete.vue';
import MultipleDeviceTypeAutoComplete from "@/components/AutoCompletes/MultipleDeviceTypeAutoComplete.vue";
import FirmwareVersionsType from '@/types/FirmwareVersionsType';
import deviceType from '@/types/DeviceType';
import { getFirmwareListRequest } from "@/types/requests/getFirmwareListRequest";
import i18n from '@/lang/i18n';

export default defineComponent({
  setup() {
    return {
      i18n
    };
  },
  data() {
    return {
      form: {} as getFirmwareListRequest,
      selectedDevices: [] as deviceType[],
      selectedFirmware: undefined as FirmwareVersionsType | undefined,
      formHidden: false as boolean,
    };
  },
  props: {
    isFirmwareListLoading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  name: 'FirmwareListSearchForm',
  computed: {
    searchAreaClass() {
      return this.formHidden ? 'search-area-hide p-4' : 'search-area p-4';
    },
    filterBtnText(): string {
      return this.isFirmwareListLoading ?
        i18n.global.t('pages.common.button.loading') :
        i18n.global.t('pages.common.button.exec');
    },
  },
  emits: ['form-change', 'search'],
  methods: {
    handleClickSearch(): void {
      if (this.isFirmwareListLoading) {
        return;
      }
      this.makeSearchPamas();
      this.$emit('search', this.form);
    },
    makeSearchPamas(): void {
      const deviceIds = this.selectedDevices?.map((item: deviceType) => item.id)
      this.form.firmware_id = this.selectedFirmware?.id;
      this.form.device_type = deviceIds;
    },
    clearForm(): void {
      this.selectedFirmware = undefined;
      this.selectedDevices = [];
    },
    toggleSidebar(): void {
      this.formHidden = !this.formHidden;
    },
  },
  components: {
    FirmwareVersionAutoComplete,
    MultipleDeviceTypeAutoComplete,
  },
});
