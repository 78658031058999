<template>
  <DetailModal v-model='modalState' :details="details" />
  <div class="col-xl-6 col-md-12" @click="openDetailModal">
    <div class="dashboard-panel" >
      <template v-if="loading">
        <div class="panel-loading">
            <label >
              {{ i18n.global.t('pages.dashboard.reading') }}
            </label>
          </div>
      </template>
      <line-chart-card
        :chartData="data"
        :options="options"
      >
        <template v-slot:header>
          <label class="chart-card-title">
            {{ i18n.global.t('pages.dashboard.safeDriving.title') }}
          </label>
        </template>
      </line-chart-card>
    </div>
  </div>
</template>

<style scoped>
.dashboard-panel {
  position: relative;
  height: 100%;
}
.panel-loading {
  height: 100%;
  position: absolute;
  z-index: 1000;
  background-color: rgba(80,80,80,0.5);
  right: 0;
  left: 0;
}
.panel-loading>label {
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
</style>

<script>
  import LineChartCard from '@/components/Cards/LineChartCard.vue'
  import DetailModal from '@/components/DashboardCards/SafeDrivingCardDetailModal'
  import Api from "@/services/api/ApiServiceFabrick";
  import i18n from '@/lang/i18n';

  export default {
    setup() {
      return {
        i18n
      }
    },
    components: {
      DetailModal,
      LineChartCard,
    },
    props: {
      operationOfficeId: null,
    },
    data () {
      return {
        data: {},
        options: {},
        modalState: false,
        details: [],
        months: [],
        loading: true,
      }
    },
    mounted() {
      this.getData();
    },
    methods: {
      openDetailModal() {
        this.modalState = !!this.details[0]
      },
      getData() {
        Api().getSafetyDrivingScoreTransitions(this.operationOfficeId).then((data) => {
          this.details = data.details
          const averages = data.averages
          const maxValue = Math.floor(Math.max(...averages)/10) * 10
          const minValue = Math.floor(Math.min(...averages)/10) * 10
          const stepSize = 20

          const max = maxValue + stepSize > 100 ? 100 : maxValue + stepSize
          const min = minValue - stepSize < 0 ? 0 : minValue - stepSize

          const months = []
          let beforeMonth = 13
          let currentYear = (new Date()).getFullYear() % 100
          data.months.forEach((month, i) => {
            if (beforeMonth < month) currentYear--
            months.push(`${currentYear}${i18n.global.t('pages.dashboard.year')}${month}${i18n.global.t('pages.dashboard.month')}`)
            beforeMonth = month
          })

          this.data = {
            labels: months.reverse(),
            datasets: [
              {
                label: i18n.global.t('pages.dashboard.safeDriving.title'),
                data: averages.reverse(),
                fill: false,
                borderColor: "#CC99CC",
                pointRadius: 0,
              }
            ]
          }
          this.options = {
            scales: {
              y: {
                min: min,
                max: max,
                ticks: {
                  stepSize: stepSize,
                  callback: function(tick) {
                    return `${tick.toString()}${i18n.global.t('pages.dashboard.safeDriving.point')}`;
                  }
                },
                grid: {
                  display: false,
                  borderColor: 'black',
                  borderWidth: 2
                },
              },
              x: {
                grid: {
                  display: false,
                  borderColor: 'black',
                  borderWidth: 2
                },
              },
            },
            plugins: {
              legend: {
                position: 'top',
              },
            }
          }
          this.loading = false
        })
      },
    },
    watch: {
      operationOfficeId: {
        handler() {
          this.getData()
        }
      }
    },
  }
</script>
