<template>
  <template v-if="!!formInputs?.form?.device">
    <v-card class="my-4 pa-4">
      <v-card-title class="headline">DEVICE</v-card-title>
      <v-card-text>
        <div class="row mr-3 v-theme--light">
          <template v-for="(title, key) in keys" :key="key">
            <Input
                :label="title"
                v-model="form[key]"
                :isShow="formFieldOptions[key].isShow"
                :isRequired="formFieldOptions[key].isRequired"
                :formInputs="formInputs"
                :readonly="selectDefault && readonlyInputs.includes(key)"
                :errors="formFieldOptions[key].errors"
            />
          </template>
          <MqttCidInput
            v-model="form.mqtt_cid"
            :errors="errors[errorKeyPrefix+'.mqtt_cid']"
            :formInputs="formInputs"
            :operationOfficeId="selectedOperationOffice?.id ?? selectedOperationOffice"
            :deviceId="selectedDevice?.id ?? selectedDevice"
          />
        </div>
      </v-card-text>
    </v-card>
  </template>
</template>

<script>
import Input from "@/components/VehicleFormParts/SettingFormInput.vue";
import MqttCidInput from "@/components/VehicleFormParts/MqttCidInput.vue";

export default {
  props: {
    modelValue: {},
    selectedDevice: {},
    selectedOperationOffice: {},
    formInputs: {},
    selectDefault: {
      type: Boolean,
      default: false
    },
    errors: {},
  },
  data() {
    return {
      form: {
        'update_master_id': '',
        'connection_type': '',
        'ip_address': '',
        'port': '',
        'keepalive_timer': '',
        'username': '',
        'password': '',
        'will_retain': '',
        'will_qos': '',
        'will_flag': '',
        'category': '',
        'http_address': '',
        'filesvr_address': '',
        'auid': '',
        'aupw': '',
        'ini_id': '',
        'ini_pw': '',
        'fw_id': '',
        'fw_pw': '',
        'fileupload_address': '',
        'fileupload_id': '',
        'fileupload_pw': '',
        'sim_apn': '',
        'sim_id': '',
        'sim_pass': '',
      },
      readonlyInputs: [],
      errorKeyPrefix: 'initial_params.device',
      keys: {
        'update_master_id': 'UPDATE_MASTER_ID',
        'connection_type': 'CONNECTION_TYPE',
        'ip_address': 'MQTT Endpoint',
        'port': 'PORT',
        'keepalive_timer': 'KEEPALIVE',
        'username': 'USER_NAME',
        'password': 'PASSWORD',
        'will_retain': 'WILL_RETAIN',
        'will_qos': 'WILL_QOS',
        'will_flag': 'WILL_FLAG',
        'category': 'CATEGORY',
        'http_address': 'HTTP_ADDRESS',
        'filesvr_address': 'FILESVR_ADDRESS',
        'auid': 'AUID',
        'aupw': 'AUPW',
        'ini_id': 'INIID',
        'ini_pw': 'INIPW',
        'fw_id': 'FWID',
        'fw_pw': 'FWPW',
        'fileupload_address': 'FILEUPLOAD_ADDRESS',
        'fileupload_id': 'FILEUPLOAD_ID',
        'fileupload_pw': 'FILEUPLOAD_PW',
        'sim_apn': 'SIM_APN',
        'sim_id': 'SIM_ID',
        'sim_pass': 'SIM_PASS',
      }
    }
  },
  methods: {
    getShowAndRequiredAndErrors(parentKey, formKeys) {
      if (!this.formInputs?.form[parentKey]) {
        return {
          'isShow': false,
          'isRequired': false,
          'errors': null
        };
      }

      const keys = Object.keys(this.formInputs.form[parentKey]);

      const result = formKeys.map(formKey => {
        return {
          'isShow': keys.includes(formKey),
          'isRequired': !!this.formInputs.form[parentKey][formKey]?.required,
          'errors': this.errors[this.errorKeyPrefix + '.' + formKey]
        };
      });

      return {
        'isShow': result.every(item => item.isShow),
        'isRequired': result.every(item => item.isRequired),
        'errors': result.map(item => item.errors).filter(error => error)
      };
    }
  },
  computed: {
    formFieldOptions() {
      const parentKey = 'device'
      const options = {}

      Object.keys(this.keys).forEach(key => {
        options[key] = this.getShowAndRequiredAndErrors(parentKey, [key])
      })

      return options
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.form = Object.assign(this.form, this.modelValue)
      },
      deep: true,
    },
    formInputs: {
      handler() {
        if (!this.formInputs?.form?.device) return
        const key = Object.keys(this.formInputs.default_params)[0]
        this.readonlyInputs = Object.keys(this.formInputs.default_params[key]?.parameters?.device ?? [])
      }
    },
    form: {
      handler() {
        this.$emit('update:modelValue', this.form)
      },
      deep: true
    },
  },
  components: {
    Input,
    MqttCidInput,
  },
}
</script>

<style scoped>
.headline {
  font-size: 24px;
  font-weight: bold;
  color: #0088a6;
}
</style>
