<template>
  <GDialog
    v-model="dialogState"
    max-width="90%"
    max-heigh="80%"
    transition="custom-from-bottom-transition"
  >
    <div class="modal-header justify-content-center">
      <label>
        {{ i18n.global.t('pages.dashboard.safeDriving.title') }}
      </label>
    </div>
    <div class="row mr-3 ml-3">
      <div>
        <v-autocomplete
          label="ドライバー"
          v-model="selectedDriver"
          item-title="full_name"
          item-value="full_name"
          :items="selectItems"
          return-object
        />
      </div>
    </div>
    <LineChart :chartData="data" :options="options" :height="'85'" />

    <div class="row mr-3 ml-3 list">
      <div class="col-12">
        <v-table>
          <thead>
            <tr>
              <th>{{ i18n.global.t('pages.dashboard.month') }}</th>
              <th
                v-for="(month, i) in months"
                :key="'month-' + i"
                class="table-cell"
              >
                {{ `${month}${i18n.global.t('pages.dashboard.month')}` }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ i18n.global.t('pages.dashboard.safeDriving.title') }}</td>
              <template
                v-for="(value, i) in selectedItemData ?? []"
                :key="'month-data-' + i"
              >
                <td class="table-cell" v-if="!selectedItem">
                  - {{ i18n.global.t('pages.dashboard.safeDriving.point') }}
                </td>
                <td class="table-cell" v-else>
                  {{ `${value}${i18n.global.t('pages.dashboard.safeDriving.point')}` }}
                </td>
              </template>
            </tr>
          </tbody>
        </v-table>
      </div>
    </div>
    <div class="d-flex justify-content-end m-3">
      <v-btn class="m-1 reset-btn" @click="close">
        {{ i18n.global.t('common.closeBtn') }}
      </v-btn>
    </div>
  </GDialog>
</template>

<script>
import 'gitart-vue-dialog/dist/style.css';
import { GDialog } from 'gitart-vue-dialog';
import { LineChart } from 'vue-chart-3';
import i18n from '@/lang/i18n';

export default {
  setup() {
    return {
      i18n
    }
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    details: [],
  },
  data: () => ({
    dialogState: false,
    items: [],
    data: {},
    options: {},
    selectedDriver: null,
    selectedItem: null,
    months: [],
    selectedItemData: ['', '', '', '', '', '', '', '', '', '', '', ''],
    selectItems: [],
  }),
  mounted() {
    this.dialogState = this.modelValue;
    this.setDataAndOptions();

    const months = [];
    let date = new Date();
    for (let i = 0; i < 12; i++) {
      months.push(date.getMonth() + 1);
      date = new Date(date.setMonth(date.getMonth() - 1));
    }
    this.months = months.reverse();
    this.setDrivers();
  },
  methods: {
    close() {
      this.dialogState = false;
    },
    setDriver() {
      this.selectedItem = null;
      this.selectedItemData = ['', '', '', '', '', '', '', '', '', '', '', ''];
      this.items.forEach((v) => {
        if (v.driver_id == this.selectedDriver?.driver_id) {
          this.selectedItem = v;

          if (this.selectedItem?.safe_driving_scores) {
            const data = [];
            this.selectedItem?.safe_driving_scores.forEach((v) => {
              data.push(Math.round(v * 10) / 10);
            });
            this.selectedItemData = JSON.parse(JSON.stringify(data)).reverse();
            this.months = JSON.parse(JSON.stringify(v.months)).reverse();
          }
        }
      });
    },
    setDrivers() {
      if (this.details) {
        this.details.forEach((item) => {
          item['name'] = item.last_name + ' ' + item.first_name;
        });
        this.selectItems = this.details;
        this.selectItems = this.selectItems.map((item) => {
          return {
            ...item,
            full_name: item.last_name + ' ' + item.first_name,
          };
        });
      }
    },
    setDataAndOptions() {
      this.items = this.details;
      const stepSize = 20;
      let max = 100;
      let min = 0;

      if (this.selectedItem?.safe_driving_scores) {
        const maxValue =
          Math.floor(Math.max(...this.selectedItemData) / 10) * 10;
        const minValue =
          Math.ceil(Math.min(...this.selectedItemData) / 10) * 10;

        const maxLine = Math.floor(maxValue / stepSize) * stepSize;
        max = maxLine + stepSize > 100 ? 100 : maxLine + stepSize;
        const minLine = Math.floor(minValue / stepSize) * stepSize;
        min = minLine - stepSize < 0 ? 0 : minLine - stepSize;
      }

      const months = [];
      let beforeMonth = 13;
      let currentYear = new Date().getFullYear() % 100;
      this.months.reverse().forEach((month, i) => {
        if (beforeMonth < month) currentYear--;
        months.push(`${currentYear}${i18n.global.t('pages.dashboard.year')}${month}${i18n.global.t('pages.dashboard.month')}`);
        beforeMonth = month;
      });
      this.months.reverse();

      this.data = {
        labels: months.reverse(),
        datasets: [
          {
            label: i18n.global.t('pages.dashboard.safeDriving.title'),
            data: this.selectedItemData,
            fill: false,
            borderColor: '#CC99CC',
            pointRadius: 0,
          },
        ],
      };
      this.options = {
        scales: {
          y: {
            min: min,
            max: max,
            ticks: {
              stepSize: stepSize,
              callback: function (tick) {
                return `${tick.toString()}${i18n.global.t('pages.dashboard.safeDriving.point')}`;
              },
            },
            grid: {
              display: false,
              borderColor: 'black',
              borderWidth: 2,
            },
          },
          x: {
            grid: {
              display: false,
              borderColor: 'black',
              borderWidth: 2,
            },
          },
        },
        plugins: {
          legend: {
            position: 'top',
          },
        },
      };
    },
  },
  watch: {
    dialogState: {
      handler() {
        this.$emit('update:modelValue', this.dialogState);
        if (!this.dialogState) {
          this.selectedDriver = undefined;
        }
      },
    },
    modelValue: {
      handler() {
        this.dialogState = this.modelValue;
      },
    },
    selectedDriver: {
      handler() {
        this.setDriver();
        this.setDataAndOptions();
      },
    },
    details: {
      handler() {
        this.setDataAndOptions();
        this.setDrivers();
      },
    },
  },
  components: {
    GDialog,
    LineChart,
  },
};
</script>

<style scoped>
.modal-header {
  font-weight: 600;
}
.list {
  max-height: 30vw;
  overflow: auto;
}
.table-cell {
  border-right: solid 1px;
  border-left: solid 1px;
  text-align: center;
}
</style>
