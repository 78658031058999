<template>
  <template v-if="!!formInputs?.form?.record">
    <v-card class="my-4 pa-4">
      <v-card-title class="headline">RECORD</v-card-title>
      <v-card-text>
        <div class="row mr-3 v-theme--light">
          <template v-for="(title, key) in keys" :key="key">
            <Input
                :label="title"
                v-model="form[key]"
                :isShow="formFieldOptions[key].isShow"
                :isRequired="formFieldOptions[key].isRequired"
                :formInputs="formInputs"
                :readonly="selectDefault && readonlyInputs.includes(key)"
                :errors="formFieldOptions[key].errors"
            />
          </template>
        </div>
      </v-card-text>
    </v-card>
  </template>
</template>

<script>
import Input from "@/components/VehicleFormParts/SettingFormInput.vue";

export default {
  props: {
    modelValue: {},
    formInputs: {},
    selectDefault: {
      type: Boolean,
      default: false,
    },
    errors: {},
  },
  data() {
    return {
      form: {
        camr_on: '',
        camf_bitrate: '',
        camr_bitrate: '',
        camf_fps: '',
        camr_fps: '',
        sound_on: '',
        cami_on: '',
        cami_bitrate: '',
        cami_fps: '',
      },
      readonlyInputs: [],
      errorKeyPrefix: "initial_params.record",
      keys: {
        camr_on: "CAMR_ON",
        camf_bitrate: "CAMF_BITRATE",
        camr_bitrate: "CAMR_BITRATE",
        camf_fps: "CAMF_FPS",
        camr_fps: "CAMR_FPS",
        sound_on: "SOUND_ON",
        cami_on: "CAMI_ON",
        cami_bitrate: "CAMI_BITRATE",
        cami_fps: "CAMI_FPS",
      },
    };
  },
  methods: {
    getShowAndRequiredAndErrors(parentKey, formKeys) {
      if (!this.formInputs?.form[parentKey]) {
        return {
          'isShow': false,
          'isRequired': false,
          'errors': null
        };
      }

      const keys = Object.keys(this.formInputs.form[parentKey]);

      const result = formKeys.map(formKey => {
        return {
          'isShow': keys.includes(formKey),
          'isRequired': !!this.formInputs.form[parentKey][formKey]?.required,
          'errors': this.errors[this.errorKeyPrefix + '.' + formKey]
        };
      });

      return {
        'isShow': result.every(item => item.isShow),
        'isRequired': result.every(item => item.isRequired),
        'errors': result.map(item => item.errors).filter(error => error)
      };
    }
  },
  computed: {
    formFieldOptions() {
      const parentKey = 'record'

      const options = {};

      Object.keys(this.keys).forEach(key => {
        options[key] = this.getShowAndRequiredAndErrors(parentKey, [key]);
      });

      return options;
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.form = Object.assign(this.form, this.modelValue)
      },
    },
    formInputs: {
      handler() {
        if (!this.formInputs?.form?.record) return;
        const key = Object.keys(this.formInputs.default_params)[0];
        this.readonlyInputs = Object.keys(
          this.formInputs.default_params[key]?.parameters?.record ?? []
        );
      },
    },
    form: {
      handler() {
        this.$emit("update:modelValue", this.form);
      },
      deep: true,
    },
  },
  components: {
    Input,
  },
};
</script>

<style scoped>
.headline {
  font-size: 24px;
  font-weight: bold;
  color: #0088a6;
}
</style>
