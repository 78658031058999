import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "firmware-list" }
const _hoisted_2 = {
  key: 0,
  class: "not-searched"
}
const _hoisted_3 = { class: "table-header-row" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { colspan: "9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_sort = _resolveComponent("table-sort")!
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isFirmwareListLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", null, _toDisplayString(_ctx.i18n.global.t('pages.common.list.reading')), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_table, null, {
      default: _withCtx(() => [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", _hoisted_3, [
            _createElementVNode("th", null, [
              _createVNode(_component_table_sort, {
                "column-title": _ctx.i18n.global.t('pages.firmwareList.list.id'),
                "sort-field": _ctx.sortField.ORDER_BY_FIRMWARE_ID,
                "current-sort-field": _ctx.firmwareListSort.sort,
                "current-sort-type": _ctx.firmwareListSort.sort_type,
                onChangeSort: _ctx.changeSort,
                onToggleSort: _ctx.toggleSort
              }, null, 8, ["column-title", "sort-field", "current-sort-field", "current-sort-type", "onChangeSort", "onToggleSort"])
            ]),
            _createElementVNode("th", null, _toDisplayString(_ctx.i18n.global.t('pages.firmwareList.version')), 1),
            _createElementVNode("th", null, [
              _createVNode(_component_table_sort, {
                "column-title": _ctx.i18n.global.t('pages.firmwareList.list.typeNumber'),
                "sort-field": _ctx.sortField.ORDER_BY_DEVICE_TYPE,
                "current-sort-field": _ctx.firmwareListSort.sort,
                "current-sort-type": _ctx.firmwareListSort.sort_type,
                onChangeSort: _ctx.changeSort,
                onToggleSort: _ctx.toggleSort
              }, null, 8, ["column-title", "sort-field", "current-sort-field", "current-sort-type", "onChangeSort", "onToggleSort"])
            ]),
            _createElementVNode("th", null, [
              _createVNode(_component_table_sort, {
                "column-title": _ctx.i18n.global.t('pages.firmwareList.valid'),
                "sort-field": _ctx.sortField.ORDER_BY_ACTIVE,
                "current-sort-field": _ctx.firmwareListSort.sort,
                "current-sort-type": _ctx.firmwareListSort.sort_type,
                onChangeSort: _ctx.changeSort,
                onToggleSort: _ctx.toggleSort
              }, null, 8, ["column-title", "sort-field", "current-sort-field", "current-sort-type", "onChangeSort", "onToggleSort"])
            ]),
            _createElementVNode("th", null, [
              _createVNode(_component_table_sort, {
                "column-title": _ctx.i18n.global.t('pages.firmwareList.list.updateAt'),
                "sort-field": _ctx.sortField.ORDER_BY_UPDATED_AT,
                "current-sort-field": _ctx.firmwareListSort.sort,
                "current-sort-type": _ctx.firmwareListSort.sort_type,
                onChangeSort: _ctx.changeSort,
                onToggleSort: _ctx.toggleSort
              }, null, 8, ["column-title", "sort-field", "current-sort-field", "current-sort-type", "onChangeSort", "onToggleSort"])
            ]),
            _createElementVNode("th", null, [
              _createVNode(_component_table_sort, {
                "column-title": _ctx.i18n.global.t('pages.firmwareList.list.updateBy'),
                "sort-field": _ctx.sortField.ORDER_BY_UPDATED_USER,
                "current-sort-field": _ctx.firmwareListSort.sort,
                "current-sort-type": _ctx.firmwareListSort.sort_type,
                onChangeSort: _ctx.changeSort,
                onToggleSort: _ctx.toggleSort
              }, null, 8, ["column-title", "sort-field", "current-sort-field", "current-sort-type", "onChangeSort", "onToggleSort"])
            ]),
            _createElementVNode("th", null, _toDisplayString(_ctx.i18n.global.t('pages.firmwareList.list.edit')), 1)
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_ctx.firmwares.length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.firmwares, (firmware) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: firmware.id
                }, [
                  _createElementVNode("td", null, _toDisplayString(firmware.id), 1),
                  _createElementVNode("td", null, _toDisplayString(firmware.version), 1),
                  _createElementVNode("td", null, _toDisplayString(firmware.device_types.length > 0 ? firmware.device_types.map(deviceType=>deviceType.type).join(', ') : '-'), 1),
                  _createElementVNode("td", null, _toDisplayString(firmware.active ? _ctx.i18n.global.t('pages.firmwareList.valid') : _ctx.i18n.global.t('pages.firmwareList.invalid')), 1),
                  _createElementVNode("td", null, _toDisplayString(firmware.updated_at ? _ctx.formatDateTimeWithSeconds(firmware.updated_at) : '-'), 1),
                  _createElementVNode("td", null, _toDisplayString(firmware.updated_by || '-'), 1),
                  _createElementVNode("td", null, [
                    _createElementVNode("button", {
                      onClick: ($event: any) => (_ctx.$emit('update-firmware-click', firmware.id)),
                      class: "d-flex material-symbols-outlined"
                    }, " edit_note ", 8, _hoisted_4)
                  ])
                ]))
              }), 128))
            : (!_ctx.isFirmwareListLoading)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
                  _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.i18n.global.t('pages.common.list.noData')), 1)
                ]))
              : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    })
  ]))
}