<script setup>
import Input from "@/components/VehicleFormParts/SettingFormInput.vue";
import { ref, defineProps, defineEmits, watch, computed } from 'vue'

const props = defineProps(['modelValue', 'formInputs', 'errors', 'channelKey', 'channelLabel']);
const emit = defineEmits(['update:modelValue']);

const form = ref(props.modelValue)

const modelValue = ref(props.modelValue);
const channelStatusList = ['ON', 'OFF'];

const upper_key = props.channelKey + '_upper';
const lower_key = props.channelKey + '_lower';
const errorKeyPrefix = 'initial_params.tdsensor';
const keys = {}
keys[upper_key] = 'チャンネル上限'
keys[lower_key] = 'チャンネル下限'

watch(props, () => {
  form.value = props.modelValue
  if (props.formInputs?.form?.tdsensor[props.channelKey] != undefined &&
    props.modelValue[props.channelKey] == undefined) {
    form.value[props.channelKey] = 'OFF';
  }
  if (props.formInputs?.form?.tdsensor[upper_key] != undefined &&
    props.modelValue[upper_key] === undefined) {
    form.value[upper_key] = '+155';
  }
  if (props.formInputs?.form?.tdsensor[lower_key] != undefined &&
    props.modelValue[lower_key] === undefined) {
    form.value[lower_key] = '-30';
  }
}, { deep: true })

watch(form, () => {
  emit('update:modelValue', form.value)
}, { deep: true })

const getShowAndRequiredAndErrors = (parentKey, formKeys) => {
  if (!props.formInputs?.form[parentKey]) {
    return {
      'isShow': false,
      'isRequired': false,
      'errors': null
    };
  }

  const keys = Object.keys(props.formInputs.form[parentKey]);

  const result = formKeys.map(formKey => {
    return {
      'isShow': keys.includes(formKey),
      'isRequired': !!props.formInputs.form[parentKey][formKey]?.required,
      'errors': props.errors[errorKeyPrefix + '.' + formKey]
    };
  });

  return {
    'isShow': result.every(item => item.isShow),
    'isRequired': result.every(item => item.isRequired),
    'errors': result.map(item => item.errors).filter(error => error)
  };
}


const formFieldOptions = computed(() => {
  const parentKey = 'tdsensor'
  const options = {};

  Object.keys(keys).forEach(key => {
    options[key] = getShowAndRequiredAndErrors(parentKey, [key]);
  });

  return options;
})
</script>

<template>
  <div class="col-4">
    <v-select
      class="small mt-2"
      :label="props.channelLabel+'（ON/OFF）'"
      v-model="form[channelKey]"
      :items="channelStatusList"
      :error-messages="errors[errorKeyPrefix+'.'+channelKey]"
    >
    </v-select>
  </div>
  <template v-for="(title, key) in keys" v-bind:key="key">
    <Input
      :label="title"
      v-model="form[key]"
      :isShow="formFieldOptions[key].isShow"
      :isRequired="formFieldOptions[key].isRequired"
      :formInputs="formInputs"
      :errors="formFieldOptions[key].errors"
      colClassNumber="4"
    />
  </template>
</template>
