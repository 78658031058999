<script setup>
  import StatusChangeConfirmModal from "@/components/Common/StatusCangeConfirmModal"
  import { formatDate } from "@/services/functions"
  import { reactive } from 'vue'
  import EditLinkIcon from "@/components/Common/EditLinkIcon"

  const props = defineProps({
    installationOffices: [],
    changeStatusAuthority: {
      type: Boolean,
      default: false
    },
    listLoading: {
      type: Boolean,
      default: true
    },
    updatingStatus: {
      type: Boolean,
      default: false
    },
    modelValue: null
  })

  const emits = defineEmits(['changeSortOrder', 'changeStatus'])
  const header = reactive(['ID', '社名', '担当者名', '管理会社', 'コメント', 'ステータス', '最終更新日', '最終更新者', '編集'])
  const sortableItems = reactive({'ID': 'id', '社名': 'company_name', '担当者名': 'contact_name', '管理会社': 'manage_company_name', 'ステータス': 'status', '最終更新日': 'updated_at', '最終更新者': 'updated_by' })
  const currentSort = reactive({ item: 'id', type: 'ASC' })
  const sortTypes = reactive(['ASC', 'DESC'])
  const itemName = "設置会社"

  function datetimeFormat (datetime) {
    const d = new Date(datetime)
    return formatDate(d)
  }
  function commnet (isCommnet) {
    if (isCommnet) return '有'
    return '無し'
  }
  function emitChangeSortOrder (sortItem, sortType = null) {
    if (!sortableItem(sortItem)) return
    const item = sortableItems[sortItem]
    if (sortType) {
      currentSort.type = sortType
    } else {
      if (currentSort.item == item) {
        const type = sortTypes.find(type => type != currentSort.type)
        currentSort.type = type
      } else {
        currentSort.type = sortTypes[0]
      }
    }
    currentSort.item = item
    emits('changeSortOrder', currentSort.item, currentSort.type)
  }
  function sortableItem (itemName) {
    return Object.keys(sortableItems).includes(itemName)
  }
  function addClassIfSortable (itemName) {
    if (!sortableItem(itemName)) return
    return 'sort-icon pl-2 pt-1';
  }
  function highLightIfCurrentSort (item, type) {
    if (currentSort.item != sortableItems[item] || currentSort.type != type) return
    return 'font-weight: bold;'
  }
  function applyLeftStyle (index) {
    if (index == 0) return 'left: 0;'
  }
  function emitChangeStatus (params, key) { emits('changeStatus', params, key) }
</script>

<template>
  <div class="installation-office-list">
    <template v-if="listLoading">
      <div class="list-loading">
        <label>読み込み中</label>
      </div>
    </template>
    <div class="list list-scrollbar">
      <v-table>
        <thead>
          <tr class="table-header-row">
            <th v-for="(item, index) in header" :key="index" class="table-header column-space" :style="applyLeftStyle(index)">
              <div class="d-flex">
                <span v-show="sortableItem(item)" style="display: inline-block;">
                  <div class="nc-icon nc-stre-up sort-icon" @click="emitChangeSortOrder(item, sortTypes[0])" :style="highLightIfCurrentSort(item, sortTypes[0])" style="display: block;" />
                  <div class="nc-icon nc-stre-down sort-icon" @click="emitChangeSortOrder(item, sortTypes[1])" :style="highLightIfCurrentSort(item, sortTypes[1])" style="display: block;" />
                </span>
                <span :class="addClassIfSortable(item)" @click="emitChangeSortOrder(item)">{{item}}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="installationOffices?.length != 0">
            <tr v-for="installationOffice in installationOffices" :key="installationOffice.id">
              <td class="column-space fix-td">{{installationOffice.id}}</td>
              <td class="column-space">{{installationOffice.company_name}}</td>
              <td class="column-space">{{installationOffice.contact_name ? installationOffice.contact_name : ''}}</td>
              <td class="column-space">{{installationOffice.management_officeable.company_name}}</td>
              <td class="column-space">{{commnet(installationOffice.is_comment)}}</td>
              <td class="column-space">
                <StatusChangeConfirmModal
                  :item="installationOffice"
                  :itemName="itemName"
                  :updatableStatusButton="changeStatusAuthority"
                  :updatingStatus="updatingStatus"
                  @submit="emitChangeStatus"
                />
              </td>
              <td class="column-space">{{datetimeFormat(installationOffice.updated_at)}}</td>
              <td class="column-space">{{installationOffice.updated_by.last_name + ' ' + installationOffice.updated_by.first_name}}</td>
              <td class="column-space">
                <EditLinkIcon :name="'InstallationOfficeForm'" :id="installationOffice.id" />
              </td>
            </tr>
          </template>
          <template v-else-if="installationOffices?.length == 0 && !listLoading">
            <tr>
              <td colspan="9">
                表にデータがありません。
              </td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </div>
  </div>
</template>
<style>
.installation-office-list .list table {
  position: absolute;
}
.installation-office-list .list td {
  word-break: keep-all;
}
.installation-office-list .list th {
  min-width: 7em;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}
</style>
<style scoped>
.installation-office-list {
  position: relative;
}
.installation-office-list .list {
  position: relative;
  min-width: 58vw;
  max-height: 34em;
  min-height: 35vw;
  overflow: auto;
}
.installation-office-list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
}
.installation-office-list .list .id-column {
  min-width: 2em;
}
.installation-office-list .list .updated-at-column {
  min-width: 10em;
}
.checkbox-in-grid {
  padding: 1vm;
  height: inherit !important;
  display: inline-grid !important;
}
.list-loading {
  position: absolute;
  min-width: 58vw;
  max-height: 34em;
  min-height: 35vw;
  overflow: auto;
  z-index: 1000;
  background-color: rgba(80,80,80,0.5);
  right: 0;
  left: 0;
}
.list-loading>label {
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.column-space {
  white-space: nowrap;
}
.installation-office-list .sort-icon:hover {
  cursor: pointer;
  opacity: 0.5;
}
</style>
