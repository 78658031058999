<script setup>
import Input from '@/components/VehicleFormParts/SettingFormInput.vue';
import ChannelRow from '@/components/VehicleFormParts/IniFileTdSensorSettingChannelRow';
import {ref, defineProps, defineEmits, watch, computed} from 'vue';
const props = defineProps([
  'selectedOperationOffice',
  'modelValue',
  'formInputs',
  'errors',
]);
const emit = defineEmits(['update:modelValue']);

const form = ref(props.modelValue);
const modelValue = ref(props.modelValue);
const tdrfsensorList = ['ON', 'OFF'];
const tdrfbandList = [0, 3];
const tdrfchList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const isShow = false;
const isRequired = false;

const errorKeyPrefix = 'initial_params.tdsensor';
const settingKeys = {
  tdrfband: 'band',
  tdrfid: 'id',
  tdrfch: 'チャンネル',
};
const thresholdKeys = {
  tds1: 'チャンネル１',
  tds2: 'チャンネル２',
  tds3: 'チャンネル３',
  tds4: 'チャンネル４',
};

watch(
  props,
  () => {
    form.value = props.modelValue ?? {};
    if (
      props.formInputs?.form?.tdsensor?.tdrfsensor != undefined &&
      props.modelValue?.tdrfsensor == undefined
    ) {
      form.value.tdrfsensor = 'OFF';
    }
    if (
      props.formInputs?.form?.tdsensor?.tdrfband != undefined &&
      props.modelValue?.tdrfband == undefined
    ) {
      form.value.tdrfband = 0;
    }
    if (
      props.formInputs?.form?.tdsensor?.tdrfch != undefined &&
      props.modelValue?.tdrfch == undefined
    ) {
      form.value.tdrfch = 0;
    }
    Object.keys(thresholdKeys).forEach((key) => {
      if (props.modelValue[key] == undefined) form.value[key] = 'OFF';
    });
  },
  { deep: true }
);

watch(
  form,
  () => {
    emit('update:modelValue', form.value);
  },
  { deep: true }
);

const getShowAndRequiredAndErrors = (parentKey, formKeys) => {
  if (!props.formInputs?.form[parentKey]) {
    return {
      'isShow': false,
      'isRequired': false,
      'errors': null
    };
  }

  const keys = Object.keys(props.formInputs.form[parentKey]);

  const result = formKeys.map(formKey => {
    return {
      'isShow': keys.includes(formKey),
      'isRequired': !!props.formInputs.form[parentKey][formKey]?.required,
      'errors': props.errors[errorKeyPrefix + formKey]
    };
  });

  return {
    'isShow': result.every(item => item.isShow),
    'isRequired': result.every(item => item.isRequired),
    'errors': result.map(item => item.errors).filter(error => error)
  };
}

const formFieldOptions = computed(() => {
  const parentKey = 'tdsensor'
  return {
    tdrfid: getShowAndRequiredAndErrors(parentKey, ['tdrfid']),
  };
})
</script>

<style scoped>
.headline {
  font-size: 24px;
  font-weight: bold;
  color: #0088a6;
}
</style>

<template>
  <template
    v-if="
      !!props.selectedOperationOffice?.tdsensor && !!formInputs?.form?.tdsensor
    "
  >
    <v-card class="my-4 pa-4">
      <v-card-title class="headline">TDSENSOR</v-card-title>
      <v-card-text>
        <div class="row mr-3 pb-0">
          <div
            class="col-6"
            v-if="formInputs?.form?.tdsensor?.tdrfsensor != undefined"
          >
            <v-select
              class="small mt-2"
              label="温度管理（ON/OFF）"
              v-model="form.tdrfsensor"
              :items="tdrfsensorList"
              :error-messages="errors[errorKeyPrefix + '.tdrfsensor']"
            >
            </v-select>
          </div>
        </div>
        <div class="row mr-3 pb-0 pt-0">
          <div
            class="col-6"
            v-if="formInputs?.form?.tdsensor?.tdrfband != undefined"
          >
            <v-select
              class="small mt-2"
              :label="settingKeys['tdrfband']"
              v-model="form.tdrfband"
              :items="tdrfbandList"
              :error-messages="errors[errorKeyPrefix + '.tdrfband']"
            />
          </div>
          <Input
              v-if="formInputs?.form?.tdsensor?.tdrfid != undefined"
              :label="settingKeys['tdrfid']"
              v-model="form.tdrfid"
              :isShow="formFieldOptions.tdrfid.isShow"
              :isRequired="formFieldOptions.tdrfid.isRequired"
              :formInputs="formInputs"
              :errors="formFieldOptions.tdrfid.errors"
          />
          <div class="col-6" v-if="formInputs?.form?.tdsensor?.tdrfch != undefined">
            <v-select
              class="small mt-2"
              :label="settingKeys['tdrfch']"
              v-model="form.tdrfch"
              :items="tdrfchList"
              :error-messages="errors[errorKeyPrefix + '.tdrfch']"
            />
          </div>
        </div>
        <div class="row mr-3 pb-4 pt-0">
          <template v-for="(label, key) in thresholdKeys" v-bind:key="key">
            <ChannelRow
              v-model="form"
              :channelKey="key"
              :channelLabel="label"
              :formInputs="formInputs"
              :errors="errors"
            />
          </template>
        </div>
      </v-card-text>
    </v-card>
  </template>
</template>
