export default {
  title: "ファームウェア一覧",
  errors: {
    getFirmwareList: "ファームウェア一覧の取得に失敗しました。",
    getFirmware: "ファームウェアの取得に失敗しました。"
  },
  version: "バージョン",
  valid: "有効",
  invalid: "無効",
  list: {
    id: "ID",
    typeNumber: "対応型番",
    updateAt: "最終更新日",
    updateBy: "最終更新者",
    edit: "編集"
  },
  form: {
    title: {
      create: "作成",
      update: "更新"
    },
    label: {
      firmware: "ファームウェア",
      hash: "ハッシュ値",
      memo: "メモ"
    },
    button: {
      saving: "保存中..."
    },
    validate: {
      versionEmpty: "バージョンを入力してください。",
      format: "正しい形式を入力してください：[英数字][-][.][_]",
      versionOverLenght: "バージョンは 128 文字を超えることはできません。",
      noAttached: "ファームウェアを添付してください。"
    }
  }
}
