<template>
  <template v-if="!!formInputs?.form?.gsensor">
    <v-card class="my-4 pa-4">
      <v-card-title class="headline">GSENSOR</v-card-title>
      <v-card-text>
        <div class="row mr-3 v-theme--light">
          <template v-for="(title, key) in keys" v-bind:key="key">
            <Input
              :label="title"
              v-model="form[key]"
              :isShow="formFieldOptions[key].isShow"
              :isRequired="formFieldOptions[key].isRequired"
              :formInputs="formInputs"
              :readonly="isReadonly(key)"
              :errors="formFieldOptions[key].errors"
            />
          </template>
          <div
              class="col-6"
              v-if="formInputs?.form?.gsensor?.acceleration != undefined"
          >
            <v-select
              class="small mt-2"
              label="加速度設定"
              v-model="form.acceleration"
              :items="accelerationList"
              :error-messages="errors[errorKeyPrefix + '.acceleration']"
              :clearable="false"
            />
          </div>
          <div
              class="col-6"
              v-if="formInputs?.form?.gsensor?.acceleration_forward != undefined"
          >
            <v-select
              class="small mt-2"
              label="急加速（速度差）"
              v-model="form.acceleration_forward"
              :items="accelerationForwardList"
              :disabled="form.acceleration == 0"
              :error-messages="errors[errorKeyPrefix + '.acceleration_forward']"
              :clearable="false"
            />
          </div>
          <div
              class="col-6"
              v-if="formInputs?.form?.gsensor?.acceleration_backward != undefined"
          >
            <v-select
              class="small mt-2"
              label="急ブレーキ（速度差）"
              v-model="form.acceleration_backward"
              :items="accelerationBackwardList"
              :disabled="form.acceleration == 0"
              :error-messages="errors[errorKeyPrefix + '.acceleration_backward']"
              :clearable="false"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </template>
</template>

<script>
import Input from '@/components/VehicleFormParts/SettingFormInput.vue';

export default {
  props: {
    modelValue: {},
    formInputs: {},
    selectDefault: {
      type: Boolean,
      default: false,
    },
    errors: {},
  },
  data() {
    return {
      form: {},
      readonlyInputs: [],
      errorKeyPrefix: 'initial_params.gsensor',
      keys: {
        horizontal: '急旋回',
        backward: '急減速',
        forward: '急加速',
      },
      accelerationList: [0, 1],
      accelerationForwardList: [
        18.0, 16.2, 14.4, 12.6, 10.8, 9.0, 7.2, 5.4, 3.6, 3.2, 2.9, 2.5, 2.2,
        1.8,
      ],
      accelerationBackwardList: [
        18.0, 16.2, 14.4, 12.6, 10.8, 9.0, 7.2, 5.4, 3.6, 3.2, 2.9, 2.5, 2.2,
        1.8,
      ],
    };
  },
  methods: {
    getShowAndRequiredAndErrors(parentKey, formKeys) {
      if (!this.formInputs?.form[parentKey]) {
        return {
          'isShow': false,
          'isRequired': false,
          'errors': null
        };
      }

      const keys = Object.keys(this.formInputs.form[parentKey]);

      const result = formKeys.map(formKey => {
        return {
          'isShow': keys.includes(formKey),
          'isRequired': !!this.formInputs.form[parentKey][formKey]?.required,
          'errors': this.errors[this.errorKeyPrefix + '.' + formKey]
        };
      });

      return {
        'isShow': result.every(item => item.isShow),
        'isRequired': result.every(item => item.isRequired),
        'errors': result.map(item => item.errors).filter(error => error)
      };
    },
    isReadonly(key) {
      let readonlyStatus = true

      if (this.form.acceleration == 0) {
        readonlyStatus = false
      } else if (this.form.acceleration == 1) {
        if (key == 'horizontal') {
          readonlyStatus = false
        }
      }

      return readonlyStatus;
    }
  },
  computed: {
    formFieldOptions() {
      const parentKey = 'gsensor'

      const options = {
        machineId: this.getShowAndRequiredAndErrors(parentKey, ['machine_id']),
      };

      Object.keys(this.keys).forEach(key => {
        options[key] = this.getShowAndRequiredAndErrors(parentKey, [key]);
      });

      return options;
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.form = this.modelValue;
      },
    },
    formInputs: {
      handler() {
        if (!this.formInputs?.form?.gsensor) return;
        const key = Object.keys(this.formInputs.default_params)[0];
        this.readonlyInputs = Object.keys(
          this.formInputs.default_params[key]?.parameters?.gsensor ?? []
        );
      },
    },
    form: {
      handler() {
        this.$emit('update:modelValue', this.form);
      },
      deep: true,
    },
    'form.acceleration': {
      handler() {
        if (this.form.acceleration == '0') {
          this.form.acceleration_backward = null;
          this.form.acceleration_forward = null;
        } else if (this.form.acceleration == '1') {
          this.form.backward = null;
          this.form.forward = null;
        }
      },
    },
  },
  components: {
    Input,
  },
};
</script>

<style scoped>
.headline {
  font-size: 24px;
  font-weight: bold;
  color: #0088a6;
}
</style>
