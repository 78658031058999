<template>
  <SettingFormInput
    label="MQTT_CID"
    v-model="mqttCid"
    formKey="mqtt_cid"
    parentKey="device"
    :formInputs="formInputs"
    :readonly="true"
    :errors="errors"
  />
</template>

<script setup>
import SettingFormInput from "@/components/VehicleFormParts/SettingFormInput.vue";
import Api from "@/services/api/ApiServiceFabrick";
import { defineProps, defineEmits, ref, toRefs, onMounted, watch } from 'vue'
const props = defineProps({
  modelValue: {},
  selectDefault: Array,
  errors: Array,
  formInputs: {},
  operationOfficeId: null,
  deviceId: null,
})
const emit = defineEmits(['update:modelValue'])
const mqttCid = ref("");

const { deviceId, operationOfficeId, formInputs, modelValue } = toRefs(props);

let devices = [];
let operationOffices = [];
let beforeDeviceId;
let beforeOperationOfficeId;

const getDevicesAndOperationOffices = async () => {
  if (devices[0] || operationOffices[0]) return;
  const [_devices, _operationOffices] = await Promise.all([
    Api().autoComplete.devices(),
    Api().autoCompleteOperationOffices(),
  ]);
  devices = _devices;
  operationOffices = _operationOffices;
}

watch(deviceId, () => {
  getDefaultValue()
});

watch(operationOfficeId, () => {
  getDefaultValue()
});
watch(mqttCid, () => {
  emit('update:modelValue', mqttCid.value)
});

onMounted(() => {
  getDevicesAndOperationOffices();
});

const getDefaultValue = async () => {
  await getDevicesAndOperationOffices();
  const selected_device = selectedDevice();
  const selected_operation_office = selectedOperationOffice();

  if (operationOfficeId.value == beforeOperationOfficeId &&
    deviceId.value == beforeDeviceId
  ) {
      return;
  }
  // set before params
  beforeDeviceId = deviceId.value;
  beforeOperationOfficeId = operationOfficeId.value;

  // no selected
  if (!operationOfficeId.value || !deviceId.value) {
    mqttCid.value = "";
    return;
  }

  // read only
  if (!selected_device?.imei || selected_device?.model_type != 5) {
    mqttCid.value = "";
    return;
  }

  mqttCid.value = modelValue.value;

  const mqtt_cid = `Client-${selected_device?.imei}-${selected_device?.device_id}-${selected_operation_office?.tracking_service}`;
    mqttCid.value = mqtt_cid;
}

const selectedOperationOffice = () => {
  if (!operationOfficeId.value) return null;

  let res = null;
  for(let i = 0; i < operationOffices.length; i++) {
    if (operationOffices[i].id == operationOfficeId.value) {
      res = operationOffices[i];
      break;
    }
  }

  return res;
}

const selectedDevice = () => {
  if (!deviceId.value) return null;

  let res = null;
  for(let i = 0; i < devices.length; i++) {
    if (devices[i].id == deviceId.value) {
      res = devices[i];
      break;
    }
  }

  return res;
}
</script>

