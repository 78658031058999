<template>
  <div :class="searchAreaClass">
    <h4 class="d-flex justify-content-between mt-0">
      {{ title }}
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fit=""
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
        sandboxuid="0"
        @click="toggleSidebar"
      >
        <template v-if="hide">
          <path
            d="M7.615 11.795l4.59-4.59-1.41-1.41-6 6 6 6 1.41-1.41-4.59-4.59zm9.59 6v-12h-2v12h2z"
            fill-rule="evenodd"
            sandboxuid="0"
          ></path>
        </template>
        <template v-if="!hide">
          <path
            d="M6 7.41L10.438 12 6 16.59 7.363 18l5.802-6-5.802-6L6 7.41zM16.066 6H18v12h-1.934V6z"
            sandboxuid="0"
          />
        </template>
      </svg>
    </h4>
    <div :class="`strpied-tabled-with-hover ${hide ? 'd-none' : ''}`">
      <div class="d-grid gap-2">
        <ManagementOfficeAutoComplete
          v-model="managementOffice"
          selectFirstIfOnlyOne
          :clearable="managementOfficeClearable"
        />
        <SingleOperationOfficeAutoComplete
          v-model="operationOffice"
          :managementOffice="managementOffice"
          selectFirstIfOnlyOne
          :clearable="operationOfficeClearable"
        />
        <v-text-field
          label="グループ名"
          v-model="groupName"
        />
        <div>
          <div style="font-size: 13px; color: gray; font-weight: bold">
            ステータス
          </div>
          <v-radio-group
            v-model="status"
            class="radio-group-row"
          >
            <v-radio
              value="only-active"
              label="有効のみ"
              color="blue"
            ></v-radio>
            <v-radio
              value="all"
              label="全て"
              color="blue"
            ></v-radio>
          </v-radio-group>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="col-md-3">
          <svg
            version="1.2"
            style="overflow: visible; width: 299px; height: 1px; opacity: 1; mix-blend-mode: normal; rotate(0deg);"
          >
            <defs>
              <path
                id="path-1650848226989195"
                d="M1126 703 C1126 703 1425 703 1425 703"
              ></path>
            </defs>
            <g transform="translate(-1126, -703)">
              <path
                style="
                  stroke: rgb(194, 186, 186);
                  stroke-width: 1;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  fill: none;
                "
                d="M1126 703 C1126 703 1425 703 1425 703"
              ></path>
            </g>
          </svg>
        </div>
        <div class="d-flex search-buttons">
          <v-btn
            class="ma-2 exec-btn"
            @click="handleClickSearch"
            :style="{
              backgroundColor: loading ? '#B3B8BB' : '',
            }"
          >
            {{ searchBtnLabel }}
          </v-btn>
          <v-btn class="ma-2 reset-btn" @click="clearForm"> リセット </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManagementOfficeAutoComplete from "@/components/AutoCompletes/ManagementOfficeAutoComplete";
import SingleOperationOfficeAutoComplete from "@/components/AutoCompletes/SingleOperationOfficeAutoComplete";

export default {
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    isManagementOffice: {
      type: Boolean,
      default: false,
    },
    isSubManagementOffice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchBtnLabel: "検索",
      hide: false,
      managementOffice: null,
      operationOffice: null,
      groupName: null,
      status: "only-active",
    };
  },
  computed: {
    searchAreaClass() {
      return this.hide ? "search-area-hide p-4" : "search-area p-4";
    },
    title() {
      return this.hide ? "" : "フィルター";
    },
    managementOfficeClearable() {
      return this.isManagementOffice;
    },
    operationOfficeClearable() {
      return this.isManagementOffice || this.isSubManagementOffice;
    },
  },
  methods: {
    changeSearchButton() {
      this.searchBtnLabel = this.loading ? "検索中..." : "検索";
    },
    handleClickSearch() {
      const params = {
        management_officeable_id: this.managementOffice?.id,
        management_officeable_type: this.managementOffice?.type,
        operation_office_id: this.operationOffice?.id,
        group_name: this.groupName,
        status: this.status,
      };
      this.$emit("search", params);
    },
    clearForm() {
      this.managementOffice = this.managementOfficeClearable ? undefined : this.managementOffice,
      this.operationOffice = this.operationOfficeClearable ? undefined : this.operationOffice,
      this.groupName = null;
      this.status = "only-active";
    },
    toggleSidebar() {
      this.hide = !this.hide;
    },
  },
  watch: {
    loading: {
      handler() {
        this.changeSearchButton();
      },
    },
  },
  components: {
    ManagementOfficeAutoComplete,
    SingleOperationOfficeAutoComplete,
  },
};
</script>
