

import { defineComponent } from "vue";
import FirmwareList from "@/components/Firmware/FirmwareList.vue";
import Api from "@/services/api/ApiServiceFabrick";
import PagenateItems from "@/components/PagenateItems.vue"
import FirmwareListSearchForm from "@/components/Firmware/FirmwareListSearchForm.vue";
import firmware from "@/types/Firmware";
import { getFirmwareListRequest, sortField } from "@/types/requests/getFirmwareListRequest";
import { sortType} from "@/services/consts";
import { GDialog } from 'gitart-vue-dialog'
import FirmwareUpdateForm from "@/components/Firmware/FirmwareUpdateForm.vue";
import getFirmwareRequest from "@/types/requests/getFirmwareRequest";
import updateFirmwareRequest from "@/types/requests/updateFirmwareRequest";
import FirmwareCreateForm from "@/components/Firmware/FirmwareCreateForm.vue";
import createFirmwareRequest from "@/types/requests/createFirmwareRequest";
import { calculatePageCount } from "@/services/functions";
import ManualLink from "@/components/ManualLink.vue";
import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
import i18n from '@/lang/i18n';


export default defineComponent({
  data() {
    return {
      pageName: "firmwareList",
      currentPage: 1 as number,
      totalPage: 0  as number,
      firmwares: [] as Array<firmware>,
      firmwareListFilter: {
        firmware_id: undefined as number | undefined,
        device_type: undefined as number[] | undefined,
      },
      firmwareListSort: {
        sort_type: undefined as sortType | undefined,
        sort: undefined as sortField | undefined,
      },
      isFirmwareListLoading: false as boolean,
      showFirmwareUpdateForm: false as boolean,
      firmwareToUpdate: undefined as firmware | undefined,
      isFirmwareToUpdateLoading: false as boolean,
      isFirmwareUpdating: false as boolean,
      showFirmwareCreateForm: false as boolean,
      isFirmwareCreating: false as boolean,
      createAuthority: true as boolean,
      updateAuthority: true as boolean,
    }
  },
  props: {
    showFlash: {
      type: Function,
      required: true,
    },
    loginWithRedirect: {
      type: Function,
      required: true,
    },
  },
  name: "FirmwareListViewPage",
  setup() {
    document.title = i18n.global.t('pages.firmwareList.title')
    return {
      i18n
    }
  },
  mounted() {
    this.getAuthorities()
    this.getFirmwares()
  },
  watch: {
    currentPage: {
      handler(newValue, oldValue): void {
        if (newValue !== oldValue) {
          this.getFirmwares()
        }
      }
    }
  },
  methods: {
    async getAuthorities(): Promise<void> {
      const authorityList = await AuthorityCacheApi.getWithParse();
      this.createAuthority = authorityList["octlink.firmware.create"];
      this.updateAuthority = authorityList["octlink.firmware.update"];
      return Promise.resolve();
    },
    getFirmwares(): void {
      this.isFirmwareListLoading = true

      Api().getFirmwareList(this.makeRequestParams()).then((data) => {
        this.firmwares = data.firmwares
        this.totalPage = calculatePageCount(data.pagination.all_elements_count, data.pagination.elements_per_page)
        this.isFirmwareListLoading = false
      }).catch((error) => {
        const res = error.response
        switch (res.status) {
          case 401:
            this.loginWithRedirect({appState: {targetUrl: location.pathname}})
            break
          case 403:
            this.$router.push({
              name: 'dashboard',
            })
            break
          case 422:
            this.showFlash(i18n.global.t('pages.firmwareList.errors.getFirmwareList'), "error", false)
        }
        this.isFirmwareListLoading = false
      })
    },
    makeRequestParams(): getFirmwareListRequest {
      const request: getFirmwareListRequest = {
        page: this.currentPage,
      };

      if ( this.firmwareListFilter.firmware_id ) request.firmware_id = this.firmwareListFilter.firmware_id
      if ( this.firmwareListFilter.device_type?.length ) request.device_type = this.firmwareListFilter.device_type

      if ( this.firmwareListSort.sort_type && this.firmwareListSort.sort ){
        request.sort = this.firmwareListSort.sort;
        request.sort_type = this.firmwareListSort.sort_type;
      }

      return request
    },
    getFirmware( firmwareID: number ): void {
      this.isFirmwareToUpdateLoading = true

      const request: getFirmwareRequest = {
        firmware_id: firmwareID
      }

      Api().getFirmware(request).then((data) => {
        this.firmwareToUpdate = data.firmware;
        this.isFirmwareToUpdateLoading = false
      }).catch((error) => {
        const res = error.response
        switch (res.status) {
          case 401:
            this.loginWithRedirect({appState: {targetUrl: location.pathname}})
            break
          case 403:
            this.$router.push({
              name: 'dashboard',
            })
            break
          case 422:
            this.showFlash(i18n.global.t('pages.firmwareList.errors.getFirmware'), "error", false)
        }
        this.isFirmwareToUpdateLoading = false
      })
    },
    handleChangeSort(sortParams: {sort_type: sortType | undefined, sort: sortField | undefined}): void {
      this.firmwareListSort = sortParams
      this.getFirmwares()
    },
    showUpdateFirmwareForm( firmwareID: number ):void {
      this.getFirmware(firmwareID)
      this.showFirmwareUpdateForm = true;
    },
    hideUpdateFirmwareForm():void {
      this.showFirmwareUpdateForm = false;
    },
    updateFirmwareAndCloseFormAndReloadFirmwareList( request:updateFirmwareRequest ): void {
      this.updateFirmware(request).then(() => {
        this.hideUpdateFirmwareForm()
        this.getFirmwares()
      })
    },
    async updateFirmware( request:updateFirmwareRequest ): Promise<void> {
      this.isFirmwareUpdating = true
      await Api().updateFirmware(request).then(() => {
        this.isFirmwareUpdating = false
        this.showFlash(i18n.global.t('pages.common.messages.success'), "success", false)
        return Promise.resolve()
      }).catch((error) => {
        const res = error.response
        switch (res.status) {
          case 401:
            this.loginWithRedirect({appState: {targetUrl: location.pathname}})
            break
          case 403:
            this.$router.push({
              name: 'dashboard',
            })
            break
          case 422:
            this.showFlash(i18n.global.t('pages.common.messages.failed'), "error", false)
        }
        this.isFirmwareUpdating = false
        return Promise.reject()
      })
    },
    showCreateFirmwareForm(): void {
      this.showFirmwareCreateForm = true
    },
    hideCreateFirmwareForm(): void {
      this.showFirmwareCreateForm = false
    },
    createFirmwareAndCloseFormAndReloadFirmwareList( request:createFirmwareRequest ): void {
      this.createFirmware(request).then(() => {
        this.hideCreateFirmwareForm()
        this.getFirmwares()
      })
    },
    async createFirmware( request:createFirmwareRequest ): Promise<void> {
      this.isFirmwareCreating = true
      await Api().createFirmware(request).then(() => {
        this.isFirmwareCreating = false
        this.showFlash(i18n.global.t('pages.common.messages.success'), "success", false)
        return Promise.resolve()
      }).catch((error) => {
        const res = error.response
        switch (res.status) {
          case 401:
            this.loginWithRedirect({appState: {targetUrl: location.pathname}})
            break
          case 403:
            this.$router.push({
              name: 'dashboard',
            })
            break
          case 422:
            this.showFlash(i18n.global.t('pages.common.messages.failed'), "error", false)
        }
        this.isFirmwareCreating = false
        return Promise.reject()
      })
    },
    resetPaginationAndGetFirmwares(requestParams: getFirmwareListRequest | null): void {
      this.resetPagination()
      this.firmwareListFilter.firmware_id = requestParams?.firmware_id
      this.firmwareListFilter.device_type = requestParams?.device_type
      this.getFirmwares()
    },
    resetPagination(): void {
      this.currentPage = 1
    }
  },
  components: {
    FirmwareList,
    pagenate: PagenateItems,
    FirmwareListSearchForm,
    GDialog,
    FirmwareUpdateForm,
    FirmwareCreateForm,
    ManualLink
  }
})
