<template>
  <div class="group-list">
    <template v-if="listLoading">
      <div class="list-loading">
        <label>読み込み中</label>
      </div>
    </template>
    <div class="list list-scrollbar">
      <v-table>
        <thead>
          <tr class="table-header-row">
            <th
              v-for="(item, index) in header"
              :key="index"
              class="table-header column-space"
              :style="applyLeftStyle(index)"
            >
              <div class="d-flex">
                <span v-show="sortableItem(item)" style="display: inline-block">
                  <div
                    class="nc-icon nc-stre-up sort-icon"
                    @click="emitChangeSortOrder(item, sortTypes[0])"
                    :style="highLightIfCurrentSort(item, sortTypes[0])"
                    style="display: block"
                  />
                  <div
                    class="nc-icon nc-stre-down sort-icon"
                    @click="emitChangeSortOrder(item, sortTypes[1])"
                    :style="highLightIfCurrentSort(item, sortTypes[1])"
                    style="display: block"
                  />
                </span>
                <span
                  :class="addClassIfSortable(item)"
                  @click="emitChangeSortOrder(item)"
                  >{{ item }}</span
                >
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="groups?.length != 0">
            <tr v-for="group in groups" :key="group.id">
              <td class="column-space fix-td">{{ group.id }}</td>
              <td class="column-space">
                {{ group.operation_office.operation_office_name }}
              </td>
              <td class="column-space">{{ group.group_name }}</td>
              <td class="column-space">{{ group.is_comment }}</td>
              <td class="column-space">
                <StatusChangeConfirmModal
                  :item="group"
                  :updatableStatusButton="changeStatusAuthority"
                  :updatingStatus="updatingStatus"
                  @submit="emitChangeStatus"
                />
              </td>
              <td class="column-space">{{ datetimeFormat(group.updated_at) }}</td>
              <td class="column-space">
                {{
                  group.updated_by.last_name + " " + group.updated_by.first_name
                }}
              </td>
              <td class="column-space">
                <EditLinkIcon :name="'GroupForm'" :id="group.id" />
              </td>
            </tr>
          </template>
          <template v-else-if="groups?.length == 0 && !listLoading">
            <tr>
              <td colspan="9">表にデータがありません。</td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import StatusChangeConfirmModal from "@/components/GroupParts/StatusChangeConfirmModal";
import EditLinkIcon from "@/components/Common/EditLinkIcon";

export default {
  props: {
    groups: [],
    changeStatusAuthority: {
      type: Boolean,
      default: false,
    },
    listLoading: {
      type: Boolean,
      default: true,
    },
    updatingStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      header: [
        "ID",
        "運営会社名",
        "グループ名",
        "コメント",
        "ステータス",
        "最終更新日",
        "最終更新者",
        "編集",
      ],
      sortableItems: {
        ID: "id",
        運営会社名: "operation_office",
        グループ名: "group_name",
        ステータス: "status",
        最終更新日: "updated_at",
        最終更新者: "updated_by",
      },
      currentSort: { item: "group_name", type: "ASC" },
      sortTypes: ["ASC", "DESC"],
    };
  },
  methods: {
    datetimeFormat(datetime) {
      const d = new Date(datetime);
      const year = d.getFullYear();
      const month = (d.getMonth() + 1).toString().padStart(2, "0");
      const day = d.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    officeableTypeName(officeableType) {
      const type = officeableType.split("\\")[2];
      return this.officeableTypeNames[type];
    },
    officeableName(group) {
      const type = group.officeable_type.split("\\")[2];
      if (type == "OperationOffice") {
        return group.officeable.operation_office_name;
      } else {
        return group.officeable.company_name;
      }
    },
    emitChangeSortOrder(sortItem, sortType = null) {
      if (!this.sortableItem(sortItem)) return;

      const item = this.sortableItems[sortItem];
      if (sortType) {
        this.currentSort.type = sortType;
      } else {
        if (this.currentSort.item == item) {
          const type = this.sortTypes.find(
            (type) => type != this.currentSort.type
          );
          this.currentSort.type = type;
        } else {
          this.currentSort.type = this.sortTypes[0];
        }
      }
      this.currentSort.item = item;
      this.$emit(
        "changeSortOrder",
        this.currentSort.item,
        this.currentSort.type
      );
    },
    sortableItem(itemName) {
      return Object.keys(this.sortableItems).includes(itemName);
    },
    addClassIfSortable(itemName) {
      if (!this.sortableItem(itemName)) return;

      return "sort-icon pl-2 pt-1";
    },
    highLightIfCurrentSort(item, type) {
      if (
        this.currentSort.item != this.sortableItems[item] ||
        this.currentSort.type != type
      )
        return;

      return "font-weight: bold;";
    },
    applyLeftStyle(index) {
      if (index == 0) return "left: 0;";
    },
    emitChangeStatus(params) {
      this.$emit("changeStatus", params);
    }
  },
  components: {
    StatusChangeConfirmModal,
    EditLinkIcon
  },
};
</script>

<style>
.group-list .list table {
  position: absolute;
}
.group-list .list td {
  word-break: keep-all;
}
.group-list .list th {
  min-width: 7em;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}
</style>
<style scoped>
.group-list {
  position: relative;
}
.group-list .list {
  position: relative;
  min-width: 58vw;
  max-height: 34em;
  min-height: 35vw;
  overflow: auto;
}
.group-list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
}
.group-list .list .id-column {
  min-width: 2em;
}
.group-list .list .updated-at-column {
  min-width: 10em;
}
.checkbox-in-grid {
  padding: 1vm;
  height: inherit !important;
  display: inline-grid !important;
}
.list-loading {
  position: absolute;
  min-width: 58vw;
  max-height: 34em;
  min-height: 35vw;
  overflow: auto;
  z-index: 1000;
  background-color: rgba(80,80,80,0.5);
  right: 0;
  left: 0;
}
.list-loading>label {
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.column-space {
  white-space: nowrap;
}
.group-list .sort-icon:hover {
  cursor: pointer;
  opacity: 0.5;
}
</style>
