export default {
  title: "ファームウェア管理 / アップデート",
  resultsPerPage: "ページ毎の結果",
  errors: {
    getDeviceList: "デバイス一覧の取得に失敗しました。",
    applyFirmware: "ファームウェアの適用に失敗しました。",
    deleteFirmware: "ファームウェアの適用の削除に失敗しました。"
  },
  applyDate: "適用開始日時",
  list: {
    operationOffice: "運営会社",
    numberPlate: "ナンバープレート",
    deviceId: "デバイスID",
    modelNumber: "製造番号",
    typeNumber: "対応型番",
    version: "バージョン",
  },
  button: {
    deleting: "削除中...",
    delete: "削除",
  },
  form: {
    label: {
      memo: "メモ"
    },
    button: {
      applying: "適用中...",
      apply: "適用"
    },
    validate: {
      selectFirmware: "ファームウェアを選択してください。",
      selectApplyDate: "適用開始日時を選択してください。"
    }
  }
}
