<template>
  <div class="d-flex" style="min-height: 45vw;">
    <div class="content p-4 w-100">
      <div class="d-flex">
        <h4 class="card-title mt-0">日報出力</h4>
        <p class="ml-5 pb-1 mb-1 align-self-end operation-office-initial-announce"
          v-show="operationOfficeInitialMessage && beforeSearchParams == null">
          最新3日間を表示しています
        </p>
        <div class="ml-auto ml-2 mb-1 align-self-end">
          <ManualLink :pageName="pageName" />
        </div>
      </div>
      <div v-if="false" class="mb-5 d-flex justify-end">
        <div class="btn-area d-flex">
          <div>
            <FileDownloadBtn
              :showable="showableButtonArea"
              btnClass="exec-btn mt-0 ml-1"
              btnName="給油量CSV出力"
              :type="downloadType"
              :disabled="isSelected"
              :deliveryPlans="selectedDeliveryPlans"
            />
          </div>
          <v-btn class="exec-btn mt-0 ml-1" @click="activateModalState">
            給油量CSV入力
          </v-btn>
        </div>
      </div>
      <div>
        <DailyReportList
          :pageInfo="pageInfo"
          :searched="searched"
          :operationOffice="form?.operationOffice"
          :items="dailyReportList"
          :listLoading="listLoading"
          :isOperationOffice="isOperationOffice"
          :isDepartment="isDepartment"
          @select="handleSelectDeliveryPlanId"
          @changeItem="searchDailyReportList"
          :showFlash="showFlash"
          :notReady="notReady"
          :createAuthority="createAuthority"
          :updateAuthority="updateAuthority"
        />
        <pagenate
          :range="2"
          :totalPage="totalPage"
          v-model="currentPage"
        />
        <hr />
      </div>
      <h4>出力条件</h4>
      <div>
        <DailyReportOutputLayout
          v-model="selectOutputOptions"
          :isItTenkoKeeper="isItTenkoKeeper"
          @changeIsItTenkoReacquisition="changeIsItTenkoReacquisition"
        />
        <div v-show="showableButtonArea">
          <ButtonArea
            :operationOfficeId="form.operationOffice?.id"
            :deliveryPlans="selectedDeliveryPlans"
            :options="selectOutputOptions"
            :showFlash="showFlash"
            :isItTenkoReacquisition="isItTenkoReacquisition"
            @openModal="openFailedStatusTripIdsModal"
          />
        </div>
      </div>
    </div>

    <DailyReportSearchForm
      :loading="listLoading"
      :isOperationOffice="isOperationOffice"
      :isSubManagementOffice="isSubManagementOffice"
      :isManagementOffice="isManagementOffice"
      :isDepartment="isDepartment"
      @formchange="handleChangeSerarchForm"
      @search="searchDailyReportList"
    />
    <FileUploadModal
      :modalState="modalState"
      title='給油量の入力'
      uploadBtnClass='exec-btn mt-0 ml-1'
      closeBtnClass='mt-0'
      :type="uploadType"
      @closeModal="inactivateModalState"
      :isDailyReport:="true"
      notes="ファイルを選択してください。「給油量CSV出力」でダウンロードしたファイルを編集して、こちらからアップロードします。"
    />
    <FailedTripIdsModal
      :state="failedTripListModalState"
      :modalDisplayData="modalDisplayData"
      :operationOfficeName="operationOfficeName"
      @closeModal="failedTripListModalState = false"
    />
  </div>
</template>

<script>
  import { MANAGEMENT_OFFICE_TYPE, SUB_MANAGEMENT_OFFICE_TYPE, OPERATION_OFFICE_TYPE, DEPARTMENT_OFFICE_TYPE } from "@/services/consts.ts"
  import DailyReportSearchForm from "@/components/DailyReportSearchForm"
  import DailyReportList from "@/components/DailyReportList"
  import DailyReportOutputLayout from "@/components/DailyReportOutputLayout"
  import ButtonArea from "@/components/DailyReportOutputButtonArea"
  import Api from "@/services/api/ApiServiceFabrick";
  import PagenateItems from "@/components/PagenateItems"
  import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
  import ManualLink from "@/components/ManualLink";
  import FileDownloadBtn from "@/components/DailyReportParts/DownloadButton"
  import FileUploadModal from "@/components/Files/UploadModal"
  import {ref} from 'vue'
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import { formatDate } from "@/services/functions"
  import FailedTripIdsModal from "@/components/DailyReportParts/FailedTripIdsModal.vue"
  // import isContentLoading from "@/globalVariables";

  export default {
    props: {
      showFlash: {
        type: Function
      },
      switchContentLoading: {
        type: Function
      }
    },
    data() {
      return {
        form: {},
        currentPage: 1,
        totalPage: 0,
        dailyReportList: [],
        selectedDeliveryPlans: [],
        selectOutputOptions: {},
        listLoading: false,
        searched: false,
        beforeSearchParams: null,
        isOperationOffice: false,
        isSubManagementOffice: false,
        isManagementOffice: false,
        isDepartment: false,
        operationOfficeInitialMessage: false,
        notReady: true,
        pageName: "dailyReport",
        filterItems: {
          delivery_plan_ids: [],
        },
        downloadType: "App\\Services\\Csv\\Exports\\RefuelingAmountService",
        uploadType: "App\\Services\\Csv\\Imports\\RefuelingAmountService",
        modalState: false,
        isSelected: true,
        isItTenkoReacquisition: false,
        isItTenkoKeeper: false,
        operationOfficeName: "",
        failedTripListModalState: false,
        modalDisplayData: [],
      }
    },
    computed: {
      borderWidth() {
        return '1000';
      }
    },
    setup() {
      document.title = "日報出力"
      const showableButtonArea = ref(true)
      const createAuthority = ref(true)
      const updateAuthority = ref(true)
      const setShowableButtonArea = async () => {
        const authorityList = await AuthorityCacheApi.getWithParse()
        showableButtonArea.value = !!authorityList["octlink.daily_report.output"]
        createAuthority.value = !!authorityList["octlink.daily_report.create"]
        updateAuthority.value = !!authorityList["octlink.daily_report.update"]
      }
      setShowableButtonArea()

      const { loginWithRedirect } = useAuth();

      return {
        showableButtonArea,
        updateAuthority,
        createAuthority,
        loginWithRedirect
      }
    },
    mounted() {
      this.switchContentLoading(true)
      Api().getSelfAccount().then((data) => {
        this.isManagementOffice = data.login_organization_type == MANAGEMENT_OFFICE_TYPE
        this.isSubManagementOffice = data.login_organization_type == SUB_MANAGEMENT_OFFICE_TYPE
        this.isOperationOffice = data.login_organization_type == OPERATION_OFFICE_TYPE
        this.isDepartment = data.login_organization_type == DEPARTMENT_OFFICE_TYPE
        this.switchContentLoading(false)
        this.notReady = false
        if (this.isOperationOffice || this.isDepartment) return this.getInitialDailyReport()
      })
    },
    watch: {
      currentPage: {
        handler() {
          this.getDailyReports(this.currentPage, false)
        }
      }
    },
    methods: {
      async getInitialDailyReport() {
        this.listLoading = true
        let selfDepartment
        let selfOperationOffice
        if (this.isDepartment) {
          selfDepartment = await this.getDepartment()
          selfOperationOffice = await this.getOperationOffice(selfDepartment[0].operationOfficeId)
        } else {
          selfOperationOffice = await this.getSelfOffice()
        }
        const request = this.makeInitialParams(selfOperationOffice, selfDepartment)
        this.apiGetDailyReports(request)
      },
      async getDepartment() {
        let department
        await Api().autoCompleteDepartments().then((data) => {
          department = data
        })
        return department
      },
      async getSelfOffice() {
        let selfOffice
        await Api().getSelfOffice().then((data) => {
          this.operationOfficeName = data.operation_office_name
          this.isItTenkoKeeper = !!data.tenko_type
          selfOffice = data
        })
        return selfOffice
      },
      makeInitialParams(operationoffice, departments = []) {
        let page = 1
        let start_date =  new Date()
        const end_date = new Date()
        start_date.setDate(start_date.getDate() - 3)
        const department = departments.length == 1 ? departments[0] : null

        const ret = {
          page: page,
          operation_office_id: operationoffice?.id,
          management_office_id: operationoffice?.management_officeable_id,
          management_officeable_type: operationoffice?.management_officeable_type,
          department_id: department?.id,
          daterange: this.convertDateTimeTodate(start_date) + " - " + this.convertDateTimeTodate(end_date)
        }
        ret["delivery_date"] = 1
        return ret
      },
      handleChangeSerarchForm(form) {
        this.form = form
      },
      handleSelectDeliveryPlanId(selectedDeliveryPlans) {
        this.selectedDeliveryPlans = selectedDeliveryPlans
        this.isSelected = false
        if (this.selectedDeliveryPlans.length == 0) this.isSelected = true
      },
      searchDailyReportList() {
        this.operationOfficeInitialMessage = false
        this.searched = true
        this.getDailyReports()
      },
      getDailyReports(page = 1, updateOption = true) {
        this.listLoading = true
        this.isSelected = true
        let req
        if (updateOption || !this.beforeSearchParams) {
          req = this.makeSearchParams(page)
          this.getOperationOffice(this.form?.operationOffice?.id)
          this.beforeSearchParams = req
        } else {
          req = this.beforeSearchParams
          req.page = page
        }
        this.apiGetDailyReports(req)
      },
      apiGetDailyReports(req) {
        Api().getDailyReports(req).then((data) => {
          this.dailyReportList = data.items
          this.currentPage = data.current_page
          this.totalPage = data.total_page
          this.listLoading = false
          this.operationOfficeInitialMessage = true
        }).catch((error) => {
          const res = error.response
          switch (res.status) {
            case 401:
              this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
              break
            case 403:
              location.reload()
              break
            case 422:
              this.showFlash("日報一覧の取得に失敗しました。", "error", false)
          }
          this.listLoading = false
        })
      },
      makeSearchParams(page){
        const ret = {
          page: page,
          operation_office_id: this.form?.operationOffice?.id,
          management_office_id: this.form?.managementOffice?.id,
          management_officeable_type: this.form?.managementOffice?.type,
          group_id: this.form?.group?.id,
          department_id: this.form?.department?.id,
          car_id: this.form?.vehicle?.id,
          driver_id: this.form?.driver?.driver_id,
          account_id: this.form?.driver?.id,
          daterange: this.convertDateTimeTodate(this.form?.start_date) + " - " + this.convertDateTimeTodate(this.form?.end_date),
        }
        const key = this.form.selectDateType == "returning" ? "return_date" : "delivery_date"
        ret[key] = 1
        return ret
      },
      async getOperationOffice(operationOfficeId) {
        let operationOffice
        await Api().getOperationOffice(operationOfficeId).then((data) => {
          this.operationOfficeName = data.name
          this.isItTenkoKeeper = !!data.tenko_type
          operationOffice = data
        })
        return operationOffice
      },
      convertDateTimeTodate(datetime) {
        if (!datetime) return ''
        return formatDate(datetime, '/')
      },
      inactivateModalState(isReacquire) {
        this.modalState = false;
        if (isReacquire && this.beforeSearchParams) this.getDailyReports();
      },
      activateModalState() {
        this.modalState = true;
      },
      changeIsItTenkoReacquisition(bool) {
        this.isItTenkoReacquisition = bool
      },
      openFailedStatusTripIdsModal(tripIdListAndStatus) {
        this.failedTripListModalState = true
        this.modalDisplayData = tripIdListAndStatus
      }
    },
    components: {
      DailyReportSearchForm,
      DailyReportList,
      DailyReportOutputLayout,
      ButtonArea,
      pagenate: PagenateItems,
      ManualLink,
      FileDownloadBtn,
      FileUploadModal,
      FailedTripIdsModal,
    }
  }
</script>

<style scoped>
.main-panel {
  height: initial !important;
  max-height: initial !important;
}
.ready-display {
  height: 100%;
  position: absolute;
  z-index: 1001;
  background-color: rgba(80,80,80,0.9);
  right: 0;
  left: 0;
}
.ready-display>label {
  font-weight: bold;
  color: white;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.exec-btn {
  background-color: rgb(21, 130, 156);
  color: white !important;
}
.reset-btn {
  background-color: rgb(245, 246, 247);
  color: rgb(21, 130, 156) !important;
  font-weight: bold;
}
.operation-office-initial-announce {
  font-size: 10px
}

</style>
