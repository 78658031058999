<script setup>
  const props = defineProps({
    pageName: { type: String }
  })

  const manualLinks = {
    dashboard: "https://cdn.smart-trucking.services/manual/manual_dashboard_octlink.pdf",
    dailyReport: "https://cdn.smart-trucking.services/manual/daily-report_octlink.pdf",
    dailyReportLayout: "https://cdn.smart-trucking.services/manual/layout_octlink.pdf",
    every24HoursReport: "https://cdn.smart-trucking.services/manual/24data_octlink.pdf",
    monthlyReport: "https://cdn.smart-trucking.services/manual/monthly-report_octlink.pdf",
    safeDrivingReport: "https://cdn.smart-trucking.services/manual/safe-driving-report_octlink.pdf",
    manageAccount: "https://cdn.smart-trucking.services/manual/accounts_octlink.pdf",
    manageVehicle: "https://cdn.smart-trucking.services/manual/vehicles_octlink.pdf",
    manageOperationOffice: "https://cdn.smart-trucking.services/manual/operation-office_octlink.pdf",
    manageSpot: "https://cdn.smart-trucking.services/manual/spots_octlink.pdf",
    safeDrivingSetting: "https://cdn.smart-trucking.services/manual/score-report_octlink.pdf",
    groupList: "https://cdn.smart-trucking.services/manual/groups_octlink.pdf",
    downloadData: "https://cdn.smart-trucking.services/manual/dataoutput_octlink.pdf",
    cvrUploadList: "https://cdn.smart-trucking.services/manual/cvrvideo_octlink.pdf",
    firmwareList: "https://cdn.smart-trucking.services/manual/lists-of-firmware_octlink.pdf",
    firmwareUpdate: "https://cdn.smart-trucking.services/manual/firmware-settings_octlink.pdf",
    installation: "https://cdn.smart-trucking.services/manual/Installation_octlink.pdf",
    device: "https://cdn.smart-trucking.services/manual/device_octlink.pdf",
    allowAccessOfficce: "https://cdn.smart-trucking.services/manual/reference%20permission%20setting_octlink.pdf",
    departmentList: "https://cdn.smart-trucking.services/manual/organization_octlink.pdf",
    userRoleList: "https://cdn.smart-trucking.services/manual/authority%20management_octlink.pdf",
    roleAssignment: "https://cdn.smart-trucking.services/manual/empowerment_octlink.pdf"
  }
  
  function moveLinks () {
    return manualLinks[props.pageName]
  }
</script>

<template>
  <div>
    <a style="color: #3c8dbc; text-decoration: none;" :href=moveLinks() target="_blank">マニュアル</a>
  </div>
</template>
