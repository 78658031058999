<template>
  <div class="d-flex" style="min-height: 45vw">
    <div class="content p-4 w-100">
      <div class="d-flex">
        <h4 class="card-title mt-0">グループ一覧</h4>
        <div class="ml-auto ml-2 mb-1 align-self-end">
          <ManualLink :pageName="pageName" />
        </div>
      </div>
      <div class="mb-5 d-flex justify-end">
        <div class="btn-area">
          <v-btn
            v-show="createAuthority"
            class="exec-btn mt-0"
            @click="moveToGroupForm"
          >
            新規作成
          </v-btn>
          <FileDownloadBtn
            :showable="csvExportGroupAuthority"
            btnClass="exec-btn mt-0 ml-1"
            btnName="CSV出力"
            :type="downloadType"
            :filterItems="searchParams"
          />
          <v-btn
            v-show="csvImportGroupAuthority"
            class="exec-btn mt-0 ml-1"
            @click="activateModalState"
            >
            CSV入力
          </v-btn>
        </div>
      </div>
      <div>
        <GroupList
          :groups="groups"
          :listLoading="listLoading"
          :changeStatusAuthority="changeStatusAuthority"
          :updatingStatus="updatingStatus"
          @changeStatus="updateStatus"
          @changeSortOrder="changeSortOrder"
        />
        <pagenate :range="2" :totalPage="totalPage" v-model="currentPage" />
        <hr />
      </div>
    </div>

    <GroupsSearchForm
      :loading="listLoading"
      :isManagementOffice="isManagementOffice"
      :isSubManagementOffice="isSubManagementOffice"
      @search="getGroupsAfterSetParams"
    />

    <FileUploadModal
      :modalState="modalState"
      title='グループの入力'
      uploadBtnClass='exec-btn mt-0 ml-1'
      closeBtnClass='mt-0'
      :type="uploadType"
      templateCsvFileType="groups"
      @closeModal="inactivateModalState"
    />
  </div>
</template>

<script>
import GroupList from "@/components/GroupList";
import GroupsSearchForm from "@/components/GroupsSearchForm";
import Api from "@/services/api/ApiServiceFabrick";
import PagenateItems from "@/components/PagenateItems";
import AuthorityCacheApi from "@/services/models/AuthorityCacheApi";
import FileDownloadBtn from "@/components/Files/DownloadButton"
import FileUploadModal from "@/components/Files/UploadModal"
import { ref } from "vue";
import { useAuth } from "@/services/user/Auth0UserProvider";
import ManualLink from "@/components/ManualLink";

export default {
  props: {
    showFlash: {
      type: Function,
    },
  },
  data() {
    return {
      pageName: "groupList",
      currentPage: 1,
      totalPage: 0,
      groups: [],
      listLoading: false,
      searchParams: {
        page: 1,
        withs: "operationOffice,accounts,updatedBy",
        status: "only-active",
      },
      updatingStatus: false,
      creatingOrUpdating: false,
      downloadType: "App\\Services\\Csv\\Exports\\GroupService",
      uploadType: "App\\Services\\Csv\\Imports\\GroupService",
      modalState: ref(false),
      isManagementOffice: false,
      isSubManagementOffice: false,
      changeStatusAuthority: false,
      createAuthority: false,
      csvImportGroupAuthority: false,
      csvExportGroupAuthority: false,
    };
  },
  setup() {
    document.title = "グループ一覧";
    const { loginWithRedirect } = useAuth();

    return { loginWithRedirect };
  },
  watch: {
    currentPage: {
      handler() {
        this.searchParams.page = this.currentPage;
        this.getGroups();
      },
    },
  },
  mounted() {
    this.getAuthorityList()
    this.getGroups();
    Api().getSelfAccount().then((data) => {
      this.isManagementOffice = data.login_organization_type == 'App\\Models\\ManagementOffice'
      this.isSubManagementOffice = data.login_organization_type == 'App\\Models\\SubManagementOffice'
    })
  },
  methods: {
    async getAuthorityList(isError = false) {
      if (isError) await AuthorityCacheApi.reflesh()
      const authorityList = await AuthorityCacheApi.getWithParse()
      this.changeStatusAuthority = authorityList["octlink.group.delete"];
      this.createAuthority = authorityList["octlink.group.create"];
      this.csvImportGroupAuthority = authorityList["octlink.group.input"]
      this.csvExportGroupAuthority = authorityList["octlink.group.output"]
    },
    getGroups() {
      this.listLoading = true;

      Api()
        .getPaginatedGroups(this.searchParams)
        .then((data) => {
          this.groups = data.groups;
          this.currentPage = data.current_page;
          this.totalPage = data.total_page;
          this.listLoading = false;
        })
        .catch((error) => {
          const res = error.response;
          switch (res.status) {
            case 401:
              this.loginWithRedirect({
                appState: { targetUrl: location.pathname },
              });
              break;
            case 403:
              location.reload();
              break;
            case 422:
              this.showFlash(
                "グループ一覧の取得に失敗しました。",
                "error",
                false
              );
          }
          this.listLoading = false;
        });
    },
    updateStatus(params) {
      this.updatingStatus = true;
      Api()
        .updateGroupStatus(params)
        .then((_data) => {
          this.showFlash(this.$t("pages.common.messages.changeStatusSuccess"), "success");
          this.updatingStatus = false;
          this.getGroups();
        })
        .catch((error) => {
          const res = error.response;
          switch (res.status) {
            case 401:
              this.loginWithRedirect({
                appState: { targetUrl: location.pathname },
              });
              break;
            case 403:
              this.showFlash(this.$t("pages.common.messages.forbidden"), "error", false)
              this.getAuthorityList(true);
              break;
            case 422:
              this.showFlash(
                this.$t("pages.common.messages.changeStatusFail"),
                "error",
                false
              );
          }
          this.updatingStatus = false;
        });
    },
    getGroupsAfterSetParams(searchParams) {
      searchParams.page = 1;
      searchParams.withs = this.searchParams.withs;
      searchParams.sort = this.searchParams.sort;
      searchParams.sort_type = this.searchParams.sort_type;
      this.searchParams = searchParams;
      this.getGroups();
    },
    changeSortOrder(sortItem, sortType) {
      this.searchParams.sort = sortItem;
      this.searchParams.sort_type = sortType;
      if (this.currentPage == 1) {
        this.getGroups();
      } else {
        this.currentPage = 1;
      }
    },
    moveToGroupForm() {
      this.$router.push("/groups/form/");
    },
    inactivateModalState (isReacquireGroup) {
      this.modalState = false
      if (isReacquireGroup) this.getGroups()
    },
    activateModalState () {
      this.modalState = true
    }
  },
  components: {
    GroupList,
    GroupsSearchForm,
    pagenate: PagenateItems,
    FileDownloadBtn,
    FileUploadModal,
    ManualLink
  },
};
</script>
